import { getRequest, postRequest, getContractInvoicePartnerID } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractInvoicePartnerID'))
    searchTerm = `${searchTerm}, ContractInvoicePartnerID:${getContractInvoicePartnerID()},`
  return getRequest(`contractInvoicePartnerPerson/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`contractInvoicePartnerPerson/edit/${id}`)
}

export async function save(values) {
  return postRequest(`contractInvoicePartnerPerson/save`, values)
}

export function remove(values) {
  return postRequest(`contractInvoicePartnerPerson/delete`, values)
}
