import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { find, save, remove, clone, list2, export2, search2, checklupp } from '../../services/part'

function* fetchPartEdit({ id, openModal }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_PART,
    result,
    openModal,
  })
}

function* confirmModalReset() {
  yield put({
    type: actions.CONFIRM_MODAL_RESET_RECIEVER,
  })
}

function* confirmModalOpen() {
  yield put({
    type: actions.CONFIRM_MODAL_OPEN_RECIEVER,
  })
}

function* confirmOkcatch() {
  yield put({
    type: actions.CONFIRM_CHOOSE_OK_RECIEVER,
  })
}

// function* fetchPartSaveForLupp(values) {
//   yield put({
//     type: actions.REQUESTING,
//   })
//   const result = yield call(checklupp, values)
//   if (result !== null) {
//     if (result === "LuppHas") {
//       notification.info({ message: SAVE_200_MSG, description: 'LUPP has this part number.' })
//     }
//     if (result === "LuppHasNot") {
//       notification.success({ message: SAVE_200_MSG, description: 'Part created also in LUPP.' })
//     }
//   }
// }

function* newManuelPartModal() {
  yield put({
    type: actions.NEW_PART_STATUS_MODAL_RECIEVED,
  })
}

function* newManuelPartModalReset() {
  yield put({
    type: actions.NEW_PART_STATUS_MODAL_RESET_RECIEVED,
  })
}

function* fetchPartSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result2 = yield call(checklupp, values)
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
    if (values.values.partID === 0 && values.values.createAlsoInOtherContract === true) {
      if (result2 !== null) {
        if (result2 === 'LuppHas') {
          notification.info({ message: 'Information', description: 'LUPP has this part number.' })
        }
        if (result2 === 'LuppHasNot') {
          notification.success({ message: SAVE_200_MSG, description: 'Part created also in LUPP.' })
        }
      }
    }
  }
  yield put({
    type: actions.SAVE_PART_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_PARTS,
  })
}

function* fetchPartDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_PART_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_PARTS,
  })
}

function* fetchPartClone({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(clone, id)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.CLONE_PART_RECIEVED,
    result,
  })
}

function* fetchPartsFilter({
  size,
  index,
  searchTerm,
  orderBy,
  partNumber,
  description,
  manufacturer,
  insyear,
  mlfb,
  range1,
  range2,
  range3,
  range4,
  range5,
  range6,
  range7,
  range8,
  range9,
  range10,
  euorus,
  model,
  manufacturernr,
  owner,
  regalnr,
  fachnr,
  excel,
  status,
  note,
  manufacturerType,
  manufacturerDescription,
  productGroup,
  eanNumber,
  eanType,
  eanStatus,
  supplier,
}) {
  const t = localStorage.getItem('fetchPartsFilter')
  if (t !== undefined && t != null) {
    const fe = JSON.parse(t)
    if (fe != null) {
      if (size === undefined) size = fe.size || size
      if (index === undefined) index = fe.index || index
      if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
      if (orderBy === undefined) orderBy = fe.orderBy || orderBy
      partNumber = fe.partNumber || partNumber
      description = fe.description || description
      manufacturer = fe.manufacturer || manufacturer
      insyear = fe.insyear || insyear
      mlfb = fe.mlfb || mlfb
      range1 = fe.range1 || range1
      range2 = fe.range2 || range2
      range3 = fe.range3 || range3
      range4 = fe.range4 || range4
      range5 = fe.range5 || range5
      range6 = fe.range6 || range6
      range7 = fe.range7 || range7
      range8 = fe.range8 || range8
      range9 = fe.range9 || range9
      range10 = fe.range10 || range10

      euorus = fe.euorus || euorus
      model = fe.model || model
      manufacturernr = fe.manufacturernr || manufacturernr
      owner = fe.owner || owner

      regalnr = fe.regalnr || regalnr
      fachnr = fe.fachnr || fachnr

      status = fe.status || status
      note = fe.note || note

      manufacturerType = fe.manufacturerType || manufacturerType
      manufacturerDescription = fe.manufacturerDescription || manufacturerDescription
      productGroup = fe.productGroup || productGroup
      eanNumber = fe.eanNumber || eanNumber
      eanType = fe.eanType || eanType
      eanStatus = fe.eanStatus || eanStatus

      supplier = fe.supplier || supplier
    }
  }

  size = size || localStorage.getItem('pageSize') || 15

  localStorage.setItem(
    'fetchPartsFilter',
    JSON.stringify({
      size,
      index,
      searchTerm,
      orderBy,
      partNumber,
      description,
      manufacturer,
      insyear,
      mlfb,
      range1,
      range2,
      range3,
      range4,
      range5,
      range6,
      range7,
      range8,
      range9,
      euorus,
      model,
      manufacturernr,
      owner,
      regalnr,
      fachnr,
      range10,
      status,
      note,

      manufacturerType,
      manufacturerDescription,
      productGroup,
      eanNumber,
      eanType,
      eanStatus,

      supplier,
    }),
  )

  if (excel !== undefined && excel === true) {
    yield call(
      export2,
      partNumber,
      description,
      manufacturer,
      insyear,
      mlfb,
      range1,
      range2,
      range3,
      range4,
      range5,
      range6,
      range7,
      range8,
      range9,
      euorus,
      model,
      manufacturernr,
      owner,
      regalnr,
      fachnr,
      range10,
      status,
      note,

      manufacturerType,
      manufacturerDescription,
      productGroup,
      eanNumber,
      eanType,
      eanStatus,
      supplier,
    )
  } else {
    yield put({
      type: actions.REQUESTING,
    })

    const result = yield call(
      list2,
      size,
      index,
      searchTerm,
      orderBy,
      partNumber,
      description,
      manufacturer,
      insyear,
      mlfb,
      range1,
      range2,
      range3,
      range4,
      range5,
      range6,
      range7,
      range8,
      range9,
      euorus,
      model,
      manufacturernr,
      owner,
      regalnr,
      fachnr,
      range10,
      status,
      note,

      manufacturerType,
      manufacturerDescription,
      productGroup,
      eanNumber,
      eanType,
      eanStatus,
      supplier,
    )
    console.log('fetchPartsFilter', result)
    yield put({
      type: actions.LIST_PART,
      result,
    })
  }
}

function* partSearch({ term }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(search2, term)
  yield put({
    type: actions.SEARCH_REC,
    result,
  })
}

function* resetSearching() {
  yield put({
    type: actions.SEARCH_REC_RESET_RECIEVED,
  })
}

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.FETCH_PARTS, fetchParts),
    takeEvery(actions.SEARCH_REC_RESET, resetSearching),
    takeEvery(actions.SEARCH, partSearch),
    takeEvery(actions.FETCH_PART, fetchPartEdit),
    takeEvery(actions.CLONE_PART, fetchPartClone),
    takeEvery(actions.SAVE_PART, fetchPartSave),
    takeEvery(actions.DELETE_PART, fetchPartDelete),
    takeEvery(actions.RE_FETCH_PARTS, fetchPartsFilter),
    takeEvery(actions.FETCH_PARTS_FILTER, fetchPartsFilter),

    takeEvery(actions.NEW_PART_STATUS_MODAL, newManuelPartModal),
    takeEvery(actions.NEW_PART_STATUS_MODAL_RESET, newManuelPartModalReset),

    takeEvery(actions.CONFIRM_MODAL_RESET, confirmModalReset),
    takeEvery(actions.CONFIRM_MODAL_OPEN, confirmModalOpen),
    takeEvery(actions.CONFIRM_CHOOSE_OK, confirmOkcatch),
  ])
}
