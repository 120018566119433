import React from 'react'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { find, save, remove, list2, importData } from '../../services/inventoryClassCostType'

function* fetchInventoryClassCostTypeEdit({ id, openModal }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id, openModal)
  yield put({
    type: actions.EDIT_INVENTORYCLASSCOSTTYPE,
    result,
    openModal,
  })
}

function* fetchInventoryClassCostTypeSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_INVENTORYCLASSCOSTTYPE_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_INVENTORYCLASSCOSTTYPES,
  })
}

function* fetchInventoryClassCostTypeDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_INVENTORYCLASSCOSTTYPE_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_INVENTORYCLASSCOSTTYPES,
  })
}

function* fetchInventoryClassCostTypesFilter({
  size,
  index,
  searchTerm,
  orderBy,
  inventoryClassCode,
  costTypeCode,
  definitionCode,
  appropriation,
  code,
}) {
  try {
    const t = localStorage.getItem('fetchInventoryClassCostTypesFilter')
    console.log(t)

    if (t !== undefined && t != null) {
      try {
        const fe = JSON.parse(t)
        if (fe != null) {
          if (size === undefined) size = fe.size || size
          if (index === undefined) index = fe.index || index
          if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
          if (orderBy === undefined) orderBy = fe.orderBy || orderBy
          inventoryClassCode = fe.inventoryClassCode || inventoryClassCode
          costTypeCode = fe.costTypeCode || costTypeCode
          definitionCode = fe.definitionCode || definitionCode
          appropriation = fe.appropriation || appropriation
          code = fe.code || code
        }
      } catch (e) {
        console.log(e)
      }
    }
  } catch (e) {
    console.error(e)
  }

  size = size || localStorage.getItem('pageSize') || 15

  try {
    localStorage.setItem(
      'fetchInventoryClassCostTypesFilter',
      JSON.stringify({
        size,
        index,
        searchTerm,
        orderBy,
        inventoryClassCode,
        costTypeCode,
        definitionCode,
        appropriation,
        code,
      }),
    )
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: actions.REQUESTING,
  })

  const result = yield call(
    list2,
    size,
    index,
    searchTerm,
    orderBy,
    inventoryClassCode,
    costTypeCode,
    definitionCode,
    appropriation,
    code,
  )

  yield put({
    type: actions.LIST_INVENTORYCLASSCOSTTYPE,
    result,
  })
}

function* fetchInventoryClassCostTypeImport(values) {
  yield put({
    type: actions.REQUESTING,
  })

  const result = yield call(importData, values)
  if (result !== null) {
    if (result.status === 1) {
      notification.success({ message: DEL_200_MSG, description: result.message, duration: 0 })
    } else {
      let errors = ''
      result.value.errors.map(x => {
        errors += `\n${x.primaryValue}\n`
        errors += `*${x.errorMessages.map(z => z.errorMessage).join('\n')}\n`
        return errors
      })

      notification.error({
        duration: 0,
        description: (
          <>
            {result.message}
            <br />
            <br />
            {errors.split('\n').map(error => {
              return (
                <>
                  {error}
                  <br />
                </>
              )
            })}
          </>
        ),
        message: 'Error',
      })
    }
  }
  yield put({
    type: actions.RE_FETCH_INVENTORYCLASSCOSTTYPES,
  })
}

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.FETCH_INVENTORYCLASSCOSTTYPES, fetchInventoryClassCostTypes),
    takeEvery(actions.FETCH_INVENTORYCLASSCOSTTYPE, fetchInventoryClassCostTypeEdit),
    takeEvery(actions.SAVE_INVENTORYCLASSCOSTTYPE, fetchInventoryClassCostTypeSave),
    takeEvery(actions.DELETE_INVENTORYCLASSCOSTTYPE, fetchInventoryClassCostTypeDelete),
    takeEvery(actions.RE_FETCH_INVENTORYCLASSCOSTTYPES, fetchInventoryClassCostTypesFilter),
    takeEvery(actions.FETCH_INVENTORYCLASSCOSTTYPES_FILTER, fetchInventoryClassCostTypesFilter),
    takeEvery(actions.IMPORT_INVENTORYCLASSCOSTTYPE, fetchInventoryClassCostTypeImport),
  ])
}
