/* eslint-disable */

import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove } from '../../services/proposalDetail'

function* fetchProposalDetails({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, id, 2) //  unitType = 1 = Working Hours
  yield put({
    type: actions.LIST_PROPOSALDETAIL_WORKING_HOUR,
    result,
  })
}

function* fetchProposalDetailEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_PROPOSALDETAIL_WORKING_HOUR,
    result,
  })
}

function* fetchProposalDetailSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const resultSave = yield call(save, values)
  if (resultSave !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
    yield put({
      type: 'Refresh_Proposal',
    })
  }
}

function* fetchProposalDetailDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
    yield put({
      type: 'Refresh_Proposal',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_PROPOSALDETAIL_WORKING_HOURS, fetchProposalDetails),
    takeEvery(actions.FETCH_PROPOSALDETAIL_WORKING_HOUR, fetchProposalDetailEdit),
    takeEvery(actions.SAVE_PROPOSALDETAIL_WORKING_HOUR, fetchProposalDetailSave),
    takeEvery(actions.DELETE_PROPOSALDETAIL_WORKING_HOUR, fetchProposalDetailDelete),
  ])
}
