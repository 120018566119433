export default async function getBasicData() {
  return [
    {
      key: 'inventorys_g',
      icon: 'fe fe-bookmark',
      url: '/inventories',
    },

    {
      key: 'mainInventory',
      icon: 'fe fe-hard-drive',
      url: '/maininventories',
    },
    {
      key: 'addresss',
      icon: 'fe fe-home',
      role: 'admin',
      url: '/addresses',
    },

    {
      key: 'elin',
      icon: 'fe fe-shopping-cart',
      url: '/inventoryclasscosttypes',
    },
    {
      key: 'clin2',
      icon: 'fe fe-command',
      url: '/costtypes',
    },
    {
      key: 'inventoryclass',
      icon: 'fe fe-hard-drive',
      url: '/inventoryclasses',
    },
    {
      key: 'technicians',
      icon: 'fe fe-git-branch',
      url: '/technicians',
    },
    {
      key: 'parts',
      icon: 'fe fe-package',
      url: '/parts',
    },
    {
      key: 'suppliers',
      icon: 'fe fe-truck',
      url: '/suppliers',
    },
    {
      key: 'contactPerson',
      icon: 'fe fe-user',
      url: '/contactPersons',
    },
    {
      key: 'intervals',
      icon: 'fe fe-clock',
      url: '/intervals',
      role: 'admin',
    },
    {
      key: 'contractYears',
      icon: 'fe fe-calendar',
      url: '/contractyears',
    },
  ]
}
