const actions = {
  REQUESTING: 'ContractPartners/REQUESTING',
  RESPONSE_ERROR: 'ContractPartners/RESPONSE_ERROR',

  FETCH_CONTRACTPARTNERS: 'ContractPartners/FETCH',
  LIST_CONTRACTPARTNER: 'ContractPartner/LIST',

  FETCH_CONTRACTPARTNER: 'ContractPartner/FETCH',
  NEW_CONTRACTPARTNER: 'ContractPartner/NEW',
  EDIT_CONTRACTPARTNER: 'ContractPartner/EDIT',
  RESET_CONTRACTPARTNER: 'ContractPartner/RESET',

  RE_FETCH_CONTRACTPARTNERS: 'ContractPartners/REFRESH',
  SAVE_CONTRACTPARTNER: 'ContractPartner/SAVE',
  SAVE_CONTRACTPARTNER_RECIEVED: 'ContractPartner/SAVE_CONTRACTPARTNER_RECIEVED',

  DELETE_CONTRACTPARTNER: 'ContractPartner/DELETE',
  DELETE_CONTRACTPARTNER_RECIEVED: 'ContractPartner/DELETE_RECIEVED',
}

export default actions
