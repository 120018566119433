import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove } from '../../services/mainInventory'
import { list as listInventory } from '../../services/inventory'

function* fetchMainInventoryListRefresh() {
  console.log('fetchMainInventoryListRefresh')
  let fe = JSON.parse(localStorage.getItem('fetchMainInventoryList'))
  if (fe == null) {
    fe = {}
    fe.size = localStorage.getItem('pageSize') || 15
    fe.index = 0
    fe.searchTerm = null
    fe.orderBy = null
  } else {
    fe.searchTerm = null
    fe.orderBy = null
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_MAININVENTORY,
    result,
  })
}

function* fetchMainInventoryList({ size, index, searchTerm, orderBy }) {
  console.log('fetchMainInventoryList', size, index, searchTerm, orderBy)
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = JSON.parse(localStorage.getItem('fetchMainInventoryList'))
    console.log('fetchMainInventoryList fe', fe)
    if (fe != null) {
      size = fe.size || 15
      index = fe.index || 0
      searchTerm = fe.searchTerm || null
      orderBy = fe.orderBy || null
    } else {
      size = size || localStorage.getItem('pageSize') || 15
      index = 0
      searchTerm = null
      orderBy = null
    }
  }
  localStorage.setItem(
    'fetchMainInventoryList',
    JSON.stringify({ size, index, searchTerm, orderBy }),
  )
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_MAININVENTORY,
    result,
  })
}

function* fetchInventoryListByMainInventoryID({ id, mainInventoryNumber }) {
  yield put({
    type: actions.REQUESTING,
  })
  const searchTerm = `MainInventoryID:${id},`
  const result = yield call(listInventory, 9999, 0, searchTerm, null)
  result.items.forEach(element => {
    element.key = element.inventoryID
  })
  yield put({
    type: actions.LIST_INVENTORYLIST_BY_MAININVENTORYID,
    inventoryList: result.items,
    mainInventoryNumber,
  })
}

function* fetchMainInventory({ id, openModal }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id, openModal)
  yield put({
    type: actions.EDIT_MAININVENTORY,
    result,
    openModal,
  })
}

function* fetchMainInventorySave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_MAININVENTORY_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_MAININVENTORY_LIST,
  })
}

function* fetchAreaDelete({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, id)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_MAININVENTORY_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_MAININVENTORY_LIST,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_MAININVENTORY_LIST, fetchMainInventoryList),
    takeEvery(actions.FETCH_MAININVENTORY, fetchMainInventory),
    takeEvery(actions.SAVE_MAININVENTORY, fetchMainInventorySave),
    takeEvery(actions.DELETE_MAININVENTORY, fetchAreaDelete),
    takeEvery(actions.RE_FETCH_MAININVENTORY_LIST, fetchMainInventoryListRefresh),
    takeEvery(actions.FETCH_INVENTORYLIST_BY_MAININVENTORYID, fetchInventoryListByMainInventoryID),
  ])
}
