import actions from './actions'

export default function AddresssReducer(state = { loading: false, lists: null }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      console.log('REQUESTING address')
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }

    case actions.LIST_ADDRESS:
      console.log('LIST_ADDRESS address')
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }

    case actions.FILTER_ADDRESS_RECIEVED:
      console.log('FILTER_ADDRESS_RECIEVED address')
      console.log('FILTER_ADDRESS_RECIEVED action', action)
      return {
        ...state,
        lists: action.result,
        loading: false,
        saveSuccess: false,
      }

    case actions.EDIT_ADDRESS:
      console.log('EDIT_ADDRESS address')
      console.log('EDIT_ADDRESS action', action)
      console.log('EDIT_ADDRESS action.openModal', action.openModal)
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
        openModal: action.openModal,
      }

    case actions.RESET_ADDRESS:
      console.log('RESET_ADDRESS address')
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
        lists: null,
        openModal: null,
      }

    case actions.NEW_ADDRESS:
      console.log('NEW_ADDRESS address')
      return {
        ...state,
        openModal: true,
        editing: { addressID: 0 },
        loading: false,
        saveSuccess: false,
      }

    case actions.CREATE_ADDRESS_RECIEVED ||
      actions.UPDATE_ADDRESS_RECIEVED ||
      actions.DELETE_ADDRESS_RECIEVED:
      console.log('CREATE_ADDRESS_RECIEVED address')
      return {
        ...state,
        editing: { addressID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }

    case actions.RESPONSE_ERROR:
      console.log('RESPONSE_ERROR address')
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    case actions.SAVE_ADDRESS_RECIEVED: {
      console.log('SAVE_ADDRESS_RECIEVED address', action)
      return {
        ...state,
        loading: false,
        saveSuccess: true,
        message: action.message,
        savedAddressID: action.result.value,
      }
    }
    default:
      return state
  }
}
