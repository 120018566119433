export default async function getMenuData() {
  return [ 
    {
      key: 'reportsworkorders',
      icon: 'fe fe-clipboard',
      url: '/reports/workorder',
    },
    {
      key: 'reportsinventory',
      icon: 'fe fe-hard-drive',
      url: '/reports/inventory',
    }, 
    {
      key: 'reporttechnician',
      icon: 'fe fe-git-branch',
      url: '/reports/technician',
    },
    {
      key: 'reportstatus',
      icon: 'fe fe-file',
      url: '/reports/status-report',
    },
    {
      key: 'invoice2s',
      icon: 'fe fe-file-text',
      url: '/invoice2s',
    }, 
    {
      key: 'completedworkorder',
      icon: 'fe fe-airplay',
      url: '/completed-workorders',
    },
    {
      key: 'completedworkorderGeneral',
      icon: 'fe fe-file-text',
      url: '/completed-wo-general-contract',
    },
    {
      key: 'maintenanceReport',
      icon: 'fe fe-file',
      url: '/reports/workorder2',
    },


  ]
}
