import { getRequest, postRequest, getcontractIdNumber } from './api'

const token = localStorage.getItem('token')

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID'))
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
  return getRequest(
    `part/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`part/edit/${id}`)
}

export async function clone(id) {
  return getRequest(`part/clone/${id}`)
}

export async function save(values) {
  return postRequest(`part/save`, values)
}

export async function checklupp(values) {
  return postRequest(`part/checklupp`, values)
}

export function remove(values) {
  return postRequest(`part/delete`, values)
}

export async function search2(term) {
  return getRequest(`part/search/${getcontractIdNumber()}/${term || '%20'}`)
}

export async function list2(
  size,
  index,
  searchTerm,
  orderBy,
  partNumber,
  description,
  manufacturer,
  insyear,
  mlfb,
  range1,
  range2,
  range3,
  range4,
  range5,
  range6,
  range7,
  range8,
  range9,
  euorus,
  model,
  manufacturernr,
  owner,
  regalnr,
  fachnr,
  range10,
  status,
  note,
  manufacturerType,
  manufacturerDescription,
  productGroup,
  eanNumber,
  eanType,
  eanStatus,
  supplier,
) {
  const contractID = getcontractIdNumber()

  return postRequest('part/get-parts3', {
    values: {
      contractID,
      size: size || 15,
      index: index || 0,
      searchTerm: searchTerm || null,
      orderBy: orderBy || null,
      partNumber,
      description,
      manufacturer,
      insyear,
      mlfb,
      range1,
      range2,
      range3,
      range4,
      range5,
      range6,
      range7,
      range8,
      range9,
      euorus,
      model,
      manufacturernr,
      owner,
      regalnr,
      fachnr,
      range10,
      status,
      note,
      manufacturerType,
      manufacturerDescription,
      productGroup,
      eanNumber,
      eanType,
      eanStatus,
      supplierID: supplier,
    },
  })
}

export async function export2(
  partNumber,
  description,
  manufacturer,
  insyear,
  mlfb,
  range1,
  range2,
  range3,
  range4,
  range5,
  range6,
  range7,
  range8,
  range9,
  euorus,
  model,
  manufacturernr,
  owner,
  regalnr,
  fachnr,
  range10,
  status,
  note,
  manufacturerType,
  manufacturerDescription,
  productGroup,
  eanNumber,
  eanType,
  eanStatus,
) {
  const contractID = getcontractIdNumber()
  const result = await postRequest('report/part-excel2', {
    values: {
      partNumber,
      description,
      manufacturer,
      insyear,
      mlfb,
      range1,
      range2,
      range3,
      range4,
      range5,
      range6,
      range7,
      range8,
      range9,
      euorus,
      model,
      manufacturernr,
      owner,
      regalnr,
      fachnr,
      range10,
      status,
      note,
      manufacturerType,
      manufacturerDescription,
      productGroup,
      eanNumber,
      eanType,
      eanStatus,
      contractID,
      token,
    },
  })

  // const url = `report/part-excel/${contractID}/${partNumber || '%20'}/${description ||
  //   '%20'}/${manufacturer || '%20'}/${insyear || '%20'}/${mlfb || '%20'}/${range1 || '%20'}/${range2 || '%20'}/${range3 || '%20'}/${range4 || '%20'}/${range5 || '%20'}/${range6 ||
  //   '%20'}/${range7 || '%20'}/${range8 || '%20'}/${range9 || '%20'}/${euorus || '%20'}/${model || '%20'}/${manufacturernr || '%20'}/${owner || '%20'}/${regalnr || '%20'}/${fachnr || '%20'}/${range10 || '%20'}/${status || '%20'}/${note || '%20'}/${manufacturerType || '%20'}/${manufacturerDescription || '%20'}/${productGroup || '%20'}/${eanNumber || '%20'}/${eanType || '%20'}/${eanStatus || '%20'}`
  // const furl = `${getMicroBaseApiUrl(report)}${url}`
  const win = window.open(
    `${process.env.REACT_APP_BASE_URL_PRINT}report/GetPartReport?filename=${result}&token=${token}`,
    '_blank',
  )
  win.focus()
}
