const actions = {
  REQUESTING: 'MainInventory/REQUESTING',
  RESPONSE_ERROR: 'MainInventory/RESPONSE_ERROR',

  FETCH_MAININVENTORY_LIST: 'MainInventory/FETCH/LIST',
  LIST_MAININVENTORY: 'MainInventory/LIST',

  FETCH_MAININVENTORY: 'MainInventory/FETCH/ONE',
  NEW_MAININVENTORY: 'MainInventory/NEW',
  EDIT_MAININVENTORY: 'MainInventory/EDIT',
  RESET_MAININVENTORY: 'MainInventory/RESET',

  RE_FETCH_MAININVENTORY_LIST: 'MainInventory/REFRESH',
  SAVE_MAININVENTORY: 'MainInventory/SAVE',
  SAVE_MAININVENTORY_RECIEVED: 'MainInventory/SAVE_MAININVENTORY_RECIEVED',

  DELETE_MAININVENTORY: 'MainInventory/DELETE',
  DELETE_MAININVENTORY_RECIEVED: 'MainInventory/DELETE_RECIEVED',

  FETCH_INVENTORYLIST_BY_MAININVENTORYID: 'MainInventory/FETCH_INVENTORYLIST_BY_MAININVENTORYID',
  LIST_INVENTORYLIST_BY_MAININVENTORYID: 'MainInventory/LIST_INVENTORYLIST_BY_MAININVENTORYID',
  CLOSE_INVENTORYLIST_BY_MAININVENTORYID_POPUP:
    'MainInventory/CLOSE_INVENTORYLIST_BY_MAININVENTORYID_POPUP',
}

export default actions
