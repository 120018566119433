import React from 'react'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove, importData } from '../../services/inventoryClass'

function* fetchInventoryClasssRefresh() {
  let fe = JSON.parse(localStorage.getItem('fetchInventoryClasss'))
  if (fe == null) {
    fe = {}
    fe.size = localStorage.getItem('pageSize') || 15
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  } else {
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_INVENTORYCLASS,
    result,
  })
}

function* fetchInventoryClasss({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = null // JSON.parse(localStorage.getItem('fetchInventoryClasss'))
    if (fe != null) {
      size = fe.size || 15
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = size || localStorage.getItem('pageSize') || 15
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem('fetchInventoryClasss', JSON.stringify({ size, index, searchTerm, orderBy }))
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_INVENTORYCLASS,
    result,
  })
}

function* fetchInventoryClassEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_INVENTORYCLASS,
    result,
  })
}

function* fetchInventoryClassSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_INVENTORYCLASS_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_INVENTORYCLASSS,
  })
}

function* fetchInventoryClassDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_INVENTORYCLASS_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_INVENTORYCLASSS,
  })
}

function* fetchInventoryClassImport(values) {
  yield put({
    type: actions.REQUESTING,
  })

  const result = yield call(importData, values)
  if (result !== null) {
    if (result.status === 1) {
      notification.success({ message: DEL_200_MSG, description: result.message, duration: 0 })
    } else {
      let errors = ''
      result.value.errors.map(x => {
        errors += `\n${x.primaryValue}\n`
        errors += `*${x.errorMessages.map(z => z.errorMessage).join('\n')}\n`
        return errors
      })

      notification.error({
        duration: 0,
        description: (
          <>
            {result.message}
            <br />
            <br />
            {errors.split('\n').map(error => {
              return (
                <>
                  {error}
                  <br />
                </>
              )
            })}
          </>
        ),
        message: 'Error',
      })
    }
  }
  yield put({
    type: actions.RE_FETCH_INVENTORYCLASSS,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_INVENTORYCLASSS, fetchInventoryClasss),
    takeEvery(actions.FETCH_INVENTORYCLASS, fetchInventoryClassEdit),
    takeEvery(actions.SAVE_INVENTORYCLASS, fetchInventoryClassSave),
    takeEvery(actions.DELETE_INVENTORYCLASS, fetchInventoryClassDelete),
    takeEvery(actions.RE_FETCH_INVENTORYCLASSS, fetchInventoryClasssRefresh),
    takeEvery(actions.IMPORT_INVENTORYCLASS, fetchInventoryClassImport),
  ])
}
