export default async function getSettingMenu() {
  return [
    {
      key: 'companys',
      icon: 'fe fe-package',
      url: '/companies',
    },

    {
      key: 'userandroles',
      icon: 'fe fe-users',
      url: '/users',
      role: 'admin',
    },
    {
      icon: 'fe fe-mail',
      key: 'emailDeliverys',
      url: '/emailDeliverys',
    },

    {
      icon: 'fe fe-send',
      key: 'pushnotifications',
      url: '/pushnotifications',
    },
  ]
}
