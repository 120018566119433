import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { list, find, save, remove } from '../../services/contract'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'

function* fetchContractsRefresh() {
  let fe = JSON.parse(localStorage.getItem('fetchContracts'))
  if (fe == null) {
    fe = {}
    fe.size = localStorage.getItem('pageSize') || 15
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  } else {
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_CONTRACT,
    result,
  })
}

function* fetchContracts({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = null // JSON.parse(localStorage.getItem('fetchContracts'))
    if (fe != null) {
      size = fe.size || 15
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = size || localStorage.getItem('pageSize') || 15
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem('fetchContracts', JSON.stringify({ size, index, searchTerm, orderBy }))
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_CONTRACT,
    result,
  })
}

function* fetchContractEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_CONTRACT,
    result,
  })
}

function* fetchContractSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_CONTRACT_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_CONTRACTS,
  })
}

function* fetchContractDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_CONTRACT_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_CONTRACTS,
  })
}

function* fetchChooseContract(payload) {
  console.log('fetchChooseContract', payload)
  /* yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, param.id)
  console.log('fetchChooseContract', result)
  localStorage.setItem('contract', JSON.stringify(result))

  yield put({
    type: actions.SELECT_CONTRACT,
    result,
  }) */

  yield put({
    type: actions.REQUESTING,
  })
  localStorage.setItem('contract', JSON.stringify(payload))
  yield put({
    type: actions.SELECT_CONTRACT,
    payload,
  })
}

function* loadContract() {
  const payload = JSON.parse(localStorage.getItem('contract'))
  if (payload !== null) {
    yield put({
      type: actions.SELECT_CONTRACT,
      payload,
    })
  }
}

function* unSelectContract() {
  localStorage.removeItem('contract')
  yield put({ type: actions.UNSELECT_CONTRACT_RECIEVED })
}

export default function* rootSaga() {
  yield all([
    loadContract(),
    takeEvery(actions.UNSELECT_CONTRACT, unSelectContract),

    takeEvery(actions.CHOOSE_CONTRACT, fetchChooseContract),
    takeEvery(actions.FETCH_CONTRACTS, fetchContracts),
    takeEvery(actions.FETCH_CONTRACT, fetchContractEdit),
    takeEvery(actions.SAVE_CONTRACT, fetchContractSave),
    takeEvery(actions.DELETE_CONTRACT, fetchContractDelete),
    takeEvery(actions.RE_FETCH_CONTRACTS, fetchContractsRefresh),
  ])
}
