/* eslint-disable */

import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import {
  list,
  find,
  save,
  remove,
  saveMaterial,
  deleteMaterial,
  getMaterial,
} from '../../services/proposalDetail'

function* fetchPROPOSALDETAIL_MATERIALs({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, id, 1) //  unitType = 1 = Material
  yield put({
    type: actions.LIST_PROPOSALDETAIL_MATERIAL,
    result,
  })
}

function* fetchPROPOSALDETAIL_MATERIALEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_PROPOSALDETAIL_MATERIAL,
    result,
  })
}

function* fetchPROPOSALDETAIL_MATERIALSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const resultSave = yield call(save, values)
  if (resultSave !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
    yield put({
      type: 'Refresh_Proposal',
    })
  }
}

function* fetchPROPOSALDETAIL_MATERIALDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
    yield put({
      type: 'Refresh_Proposal',
    })
  }
}

function* onflyMaterialGet() {
  yield put({
    type: actions.EDIT_PROPOSALDETAIL_MATERIAL_ONFLY,
    payload: true,
  })
}
function* onflyMaterialReset() {
  yield put({
    type: actions.RESET_PROPOSALDETAIL_MATERIAL_ONFLY,
  })
}

function* getMaterialCall({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getMaterial, id)
  yield put({
    type: actions.FETCH_MANUEL_PART_EDIT_RECIEVED,
    result,
  })
}

function* saveMaterialCall(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(saveMaterial, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_MANUEL_PART_EDIT_RECIEVED,
    result,
  })
  yield put({
    type: 'Refresh_Proposal',
  })
}

function* deleteMaterial2(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(deleteMaterial, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_MATERIAL_RECIEVED,
    result,
  })
  yield put({
    type: 'Refresh_Proposal',
  })
}

function* resetMaterial() {
  yield put({
    type: actions.FETCH_MANUEL_PART_RESET_RECIEVED,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_PROPOSALDETAIL_MATERIALS, fetchPROPOSALDETAIL_MATERIALs),
    takeEvery(actions.FETCH_PROPOSALDETAIL_MATERIAL, fetchPROPOSALDETAIL_MATERIALEdit),
    takeEvery(actions.SAVE_PROPOSALDETAIL_MATERIAL, fetchPROPOSALDETAIL_MATERIALSave),
    takeEvery(actions.DELETE_PROPOSALDETAIL_MATERIAL, fetchPROPOSALDETAIL_MATERIALDelete),

    takeEvery(actions.EDIT_PROPOSALDETAIL_MATERIAL_ONFLY_GET, onflyMaterialGet),
    takeEvery(actions.RESET_PROPOSALDETAIL_MATERIAL_ONFLY_GET, onflyMaterialReset),

    takeEvery(actions.FETCH_MANUEL_PART_RESET, resetMaterial),
    takeEvery(actions.FETCH_MANUEL_PART_EDIT, getMaterialCall),
    takeEvery(actions.SAVE_MANUEL_PART_EDIT, saveMaterialCall),
    takeEvery(actions.DELETE_MATERIAL, deleteMaterial2),
  ])
}
