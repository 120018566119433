import actions from './actions'

export default function AreasReducer(state = { loading: false, lists: null }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_AREA:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_AREA:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
        openModal: action.openModal,
      }
    case actions.RESET_AREA:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
        openModal: null,
      }
    case actions.NEW_AREA:
      return {
        ...state,
        openModal: true,
        editing: { areaID: 0 },
        loading: false,
        saveSuccess: false,
      }

    case actions.CREATE_AREA_RECIEVED ||
      actions.UPDATE_AREA_RECIEVED ||
      actions.DELETE_AREA_RECIEVED:
      return {
        ...state,
        editing: { areaID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    case actions.SAVE_AREA_RECIEVED: {
      return {
        ...state,
        loading: false,
        saveSuccess: true,
        message: action.message,
        savedAreaID: action.result.value,
      }
    }
    default:
      return state
  }
}
