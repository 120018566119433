import { getRequest, postRequest,getcompanyIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('CompanyID'))
  searchTerm = `${searchTerm}, CompanyID:${getcompanyIdNumber()},`;
  return getRequest(`companyAddress/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`companyAddress/edit/${id}`)
}

export async function save(values) {
  return postRequest(`companyAddress/save`, values)
}

export function remove(values) {
  return postRequest(`companyAddress/delete`, values)
}
