import { getRequest, postRequest, getcontractIdNumber } from './api'

export async function checkAssetIsUsing(inventoryID, contractID, workOrderID) {
  return getRequest(
    `workOrder/get-check-asset-is-using/${inventoryID || '%20'}/${contractID ||
      '%20'}/${workOrderID || '%20'}`,
  )
}

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID'))
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
  return getRequest(
    `workOrder/get-list/${size || '15'}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`,
  )
}

export async function generalCostView(workOrderID) {
  return getRequest(`workOrder/get-general-contract-cost-view2/${workOrderID}`)
}

export async function listByMainInventoryID(mainInventoryID) {
  return getRequest(`workOrder/getbymaininventory?mainInventoryID=${mainInventoryID}`)
}

export async function getWorkOrders2(
  size,
  index,
  searchTerm,
  orderBy,
  completedDate,
  orderNumber,
  workOrderStatus,
  subStatus,
  mainInventoryNumber,
  inventoryNumber,
  workOrderType,
  areaID,
  locationID,
  address,
  callerName,
  trackingNumber,
  partNumbers,
  technicianNames,
) {
  const contractID = getcontractIdNumber()

  const url = `workOrder/get-list2/${size || '15'}/${index || '0'}/${searchTerm ||
    '%20'}/${orderBy || '%20'}/${contractID}/${completedDate || '%20'}/${workOrderStatus ||
    '%20'}/${subStatus || '%20'}/${areaID || '%20'}/${orderNumber || '%20'}/${address ||
    '%20'}/${locationID || '%20'}/${workOrderType || '%20'}/${inventoryNumber ||
    '%20'}/${callerName || '%20'}/${trackingNumber || '%20'}/${partNumbers ||
    '%20'}/${technicianNames || '%20'}/${mainInventoryNumber || '%20'}`

  return getRequest(url)
}

export async function find(id) {
  return getRequest(`workOrder/edit/${id}`)
}
export async function nextControl(contractId, startDate, endDate) {
  return getRequest(`workOrder/get-next-control-date/${contractId}/${startDate}/${endDate}`)
}
export async function get(id) {
  return getRequest(`workOrder/get-workorder/${id}`)
}
export async function getAppointmenDate(id) {
  return getRequest(`workOrder/get-appointment-date/${id}`)
}
export async function defaults(id) {
  return getRequest(`workOrder/defaults/${id}`)
}

export async function save(values) {
  return postRequest(`workOrder/save`, values)
}

export async function createWorkOrderByProposal(proposalID) {
  console.log('createWorkOrderByProposal proposalID', proposalID)
  return postRequest(`workOrder/create-from-proposal/${proposalID}`, { values: {} })
}

export async function logAssetChange(values) {
  return postRequest(`workOrder/log-asset-from-web`, values)
}

export async function saveAsset(values) {
  return postRequest(`workOrder/save-asset`, values)
}

export async function saveService(values) {
  return postRequest(`workOrder/save-service`, values)
}

export function remove(values) {
  return postRequest(`workOrder/delete`, values)
}

export function createWorkorders(values, startDate, endDate) {
  const params = {
    values: {
      contractID: getcontractIdNumber(),
      orderNumbers: values,
      startDate,
      endDate,
    },
  }
  return postRequest(`workOrder/create-workorders`, params)
}

export function savePdf(workOrderID, file) {
  const params = {
    values: {
      workOrderID,
      file,
    },
  }
  return postRequest(`workOrder/save-pdf`, params)
}

export function skipWorkorders({ values }) {
  const params = {
    values: {
      ContractID: getcontractIdNumber(),
      InventoryIDs: values,
    },
  }
  return postRequest(`workOrder/skip-inventory`, params)
}

export async function create() {
  const ContractID = getcontractIdNumber()
  return getRequest(`workOrder/create/${ContractID}`)
}

export async function recall(workOrderID, orderNumber) {
  return getRequest(`workOrder/recall/${workOrderID}/${orderNumber || '%20'}`)
}

export async function costView(id) {
  return getRequest(`workOrder/get-costview/${id}`)
}

export async function createWithAsset(inventoryID) {
  const ContractID = getcontractIdNumber()
  return getRequest(`workOrder/create-with-asset/${ContractID}/${inventoryID}`)
}
