import { getRequest, postRequest, getContractServicePartnerID } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractServicePartnerID'))
    searchTerm = `${searchTerm}, ContractServicePartnerID:${getContractServicePartnerID()},`
  return getRequest(`contractServicePartnerPerson/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`contractServicePartnerPerson/edit/${id}`)
}

export async function save(values) {
  return postRequest(`contractServicePartnerPerson/save`, values)
}

export function remove(values) {
  return postRequest(`contractServicePartnerPerson/delete`, values)
}
