import actions from './actions'

export default function WorkOrderPartMapsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.FETCH_MANUEL_PART_EDIT_RECIEVED:
      return {
        ...state,
        updatemanuel: action.result,
        loading: false,
        saveSuccess: false,
      }

    case actions.FETCH_MANUEL_PART_RESET_RECIEVED:
      return {
        ...state,
        updatemanuel: null,
        loading: false,
      }

    case actions.DELETE_MATERIAL_RECIEVED:
      return {
        ...state,
        updatemanuel: null,
        loading: false,
      }

    case actions.LIST_WORKORDERPARTMAP:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_WORKORDERPARTMAP:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.RESET_WORKORDERPARTMAP:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
        updatemanuel: null,
      }
    case actions.NEW_WORKORDERPARTMAP:
      return {
        ...state,
        editing: { workOrderPartMapID: 0 },
        loading: false,
        saveSuccess: false,
      }
    case actions.CREATE_WORKORDERPARTMAP_RECIEVED ||
      actions.UPDATE_WORKORDERPARTMAP_RECIEVED ||
      actions.DELETE_WORKORDERPARTMAP_RECIEVED:
      return {
        ...state,
        editing: { workOrderPartMapID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.FETCH_WORKORDERPARTMAPS_QTY_RECIEVED:
      return {
        ...state,
        loading: false,
        quantity: action.result,
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    case actions.AMOUNT_UPDATED:
      return {
        ...state,
        amount: action.result,
        editing: { workOrderPartMapID: 0 },
        loading: false,
      }
    default:
      return state
  }
}
