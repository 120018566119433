import actions from './actions'

export default function InventorysReducer(
  state = {
    loading: false,
    editing: { inventoryID: 0, inventoryNumber: null },
  },
  action,
) {
  switch (action.type) {
    case actions.REQUESTING:
      console.log('Inventory REQUESTING')
      return {
        ...state,
        loading: true,
        // editing: null,
        saveSuccess: false,
      }

    case actions.ADD_LOCATION_RECIEVED:
      console.log('Inventory ADD_LOCATION_RECIEVED')
      return {
        ...state,
        locationAdd: action.payload,
      }

    case actions.ADD_LOCATION_RESET_RECIEVED:
      console.log('Inventory ADD_LOCATION_RESET_RECIEVED')
      return {
        ...state,
        locationAdd: undefined,
      }

    case actions.CONFIRM_MODAL_RESET_RECIEVER:
      console.log('Inventory CONFIRM_MODAL_RESET_RECIEVER')
      return {
        ...state,
        confirm: undefined,
        confirmCatch: undefined,
      }

    case actions.CONFIRM_CHOOSE_OK_RECIEVER:
      console.log('Inventory CONFIRM_CHOOSE_OK_RECIEVER')
      return {
        ...state,
        confirmCatch: 1,
      }

    case actions.CONFIRM_MODAL_OPEN_RECIEVER:
      console.log('Inventory CONFIRM_MODAL_OPEN_RECIEVER')
      return {
        ...state,
        confirm: 1,
      }

    case actions.LIST_INVENTORY:
      console.log('Inventory LIST_INVENTORY')
      return {
        ...state,
        // editing: { inventoryID: 0, inventoryNumber: null },
        result: action.result,
        loading: false,
        saveSuccess: false,
      }

    case actions.FETCH_MLFB_REQ:
      console.log('Inventory FETCH_MLFB_REQ')
      return {
        ...state,
        loading: true,
      }

    case actions.RESET_INVENTORY_REC:
      console.log('Inventory RESET_INVENTORY_REC')
      return {
        ...state,
        loading: false,
        editing: null,
      }

    case actions.FETCH_MLFB_RECIEVED:
      console.log('Inventory FETCH_MLFB_RECIEVED')
      return {
        ...state,
        loading: false,
        mlfb: action.result,
      }

    case actions.EDIT_INVENTORY:
      console.log('Inventory EDIT_INVENTORY')
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
        openModal: action.openModal,
      }

    case actions.RESET_INVENTORY:
      console.log('Inventory RESET_INVENTORY')
      return {
        ...state,
        editing: { inventoryID: 0, inventoryNumber: null },
        loading: false,
        saveSuccess: false,
        openModal: null,
      }

    case actions.NEW_INVENTORY:
      console.log('Inventory NEW_INVENTORY')
      return {
        ...state,
        editing: { inventoryID: 0, inventoryNumber: null },
        loading: false,
        saveSuccess: false,
        openModal: true,
      }

    case actions.DELETE_INVENTORY_RECIEVED:
      console.log('Inventory DELETE_INVENTORY_RECIEVED')
      return {
        ...state,
        editing: { inventoryID: 0 },
        loading: false,
        saveSuccess: true, // mark,
        openModal: null,
      }

    case actions.RESPONSE_ERROR:
      console.log('Inventory RESPONSE_ERROR')
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    case actions.OPEN_BULK_UPDATE_NEXT_PM_DATE_MODAL:
      console.log('Inventory OPEN_BULK_UPDATE_NEXT_PM_DATE_MODAL')
      return {
        ...state,
        selectedInventoryIDList: action.selectedrows,
        bulkUpdateNextPmDateModal: true,
      }
    case actions.CLOSE_BULK_UPDATE_NEXT_PM_DATE_MODAL:
      console.log('Inventory CLOSE_BULK_UPDATE_NEXT_PM_DATE_MODAL')
      return {
        ...state,
        selectedInventoryIDList: [],
        bulkUpdateNextPmDateModal: false,
      }

    default:
      return state
  }
}
