const actions = {
  SET_STATE: 'menu/SET_STATE',
  SET_CONTRACT_MENU: 'menu/SET_CONTRACT_MENU',
  SET_REPORT_MENU: 'menu/SET_REPORT_MENU',
  SET_MENUBASICDATA: 'menu/SET_MENUBASICDATA',
  SET_SETTING_MENU: 'menu/SET_SETTING_MENU',

  GET_DATA: 'menu/GET_DATA',
  GET_CONTRACT_MENU: 'menu/GET_CONTRACT_MENU',
  GET_REPORT_MENU: 'menu/GET_REPORT_MENU',
  GET_BASICDATA_MENU: 'menu/GET_BASICDATA_MENU',
  GET_SETTING_MENU: 'menu/GET_SETTING_MENU',

  GET_MENU_FOR_CONTRACT: 'menu/contract_based',
  GET_MENU_FOR_CONTRACT_RECEIVED: 'menu/contract_based_recieved',
}

export default actions
