const actions = {
  REQUESTING: 'CostConcepts/REQUESTING',
  RESPONSE_ERROR: 'CostConcepts/RESPONSE_ERROR',

  FETCH_COSTCONCEPTS: 'CostConcepts/FETCH',
  LIST_COSTCONCEPT: 'CostConcept/LIST',

  FETCH_COSTCONCEPT: 'CostConcept/FETCH',
  NEW_COSTCONCEPT: 'CostConcept/NEW',
  EDIT_COSTCONCEPT: 'CostConcept/EDIT',
  RESET_COSTCONCEPT: 'CostConcept/RESET',

  RE_FETCH_COSTCONCEPTS: 'CostConcepts/REFRESH',
  SAVE_COSTCONCEPT: 'CostConcept/SAVE',
  SAVE_COSTCONCEPT_RECIEVED: 'CostConcept/SAVE_COSTCONCEPT_RECIEVED',

  DELETE_COSTCONCEPT: 'CostConcept/DELETE',
  DELETE_COSTCONCEPT_RECIEVED: 'CostConcept/DELETE_RECIEVED',
}

export default actions
