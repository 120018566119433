const actions = {
  REQUESTING: 'ContractServicePartners/REQUESTING',
  RESPONSE_ERROR: 'ContractServicePartners/RESPONSE_ERROR',

  FETCH_CONTRACTSERVICEPARTNERS: 'ContractServicePartners/FETCH',
  LIST_CONTRACTSERVICEPARTNER: 'ContractServicePartner/LIST',

  FETCH_CONTRACTSERVICEPARTNER: 'ContractServicePartner/FETCH',
  NEW_CONTRACTSERVICEPARTNER: 'ContractServicePartner/NEW',
  EDIT_CONTRACTSERVICEPARTNER: 'ContractServicePartner/EDIT',
  RESET_CONTRACTSERVICEPARTNER: 'ContractServicePartner/RESET',

  RE_FETCH_CONTRACTSERVICEPARTNERS: 'ContractServicePartners/REFRESH',
  SAVE_CONTRACTSERVICEPARTNER: 'ContractServicePartner/SAVE',
  SAVE_CONTRACTSERVICEPARTNER_RECIEVED: 'ContractServicePartner/SAVE_CONTRACTSERVICEPARTNER_RECIEVED',

  DELETE_CONTRACTSERVICEPARTNER: 'ContractServicePartner/DELETE',
  DELETE_CONTRACTSERVICEPARTNER_RECIEVED: 'ContractServicePartner/DELETE_RECIEVED',
}

export default actions
