const actions = {
  REQUESTING: 'Companys/REQUESTING',
  RESPONSE_ERROR: 'Companys/RESPONSE_ERROR',

  FETCH_COMPANYS: 'Companys/FETCH',
  LIST_COMPANY: 'Company/LIST',

  FETCH_COMPANY: 'Company/FETCH',
  NEW_COMPANY: 'Company/NEW',
  EDIT_COMPANY: 'Company/EDIT',
  RESET_COMPANY: 'Company/RESET',

  RE_FETCH_COMPANYS: 'Companys/REFRESH',
  SAVE_COMPANY: 'Company/SAVE',
  SAVE_COMPANY_RECIEVED: 'Company/SAVE_COMPANY_RECIEVED',

  DELETE_COMPANY: 'Company/DELETE',
  DELETE_COMPANY_RECIEVED: 'Company/DELETE_RECIEVED',
  
  SELECT_COMPANY: 'SELECT_COMPANY',
  UNSELECT_COMPANY: 'UNSELECT_COMPANY',
  CHOOSE_COMPANY: 'CHOOSE_COMPANY'
  
}

export default actions
