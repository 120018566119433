import { getRequest, postRequest, getcontractIdNumber, getWorkorderID } from './api'
import { API_URL_INVENTORY } from '../constants/index'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID'))
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
  return getRequest(
    `${API_URL_INVENTORY}inventoryClassCostType/get-list/${size || 15}/${index || 0}/${searchTerm ||
      '%20'}/${orderBy || '%20'}`,
  )
}

export async function getCosts(classID) {
  const contractID = getcontractIdNumber()
  const workOrderID = getWorkorderID()
  return getRequest(
    `${API_URL_INVENTORY}inventoryClassCostType/costs/${contractID}/${workOrderID}/${classID}`,
  )
}

export async function find(id) {
  return getRequest(`${API_URL_INVENTORY}inventoryClassCostType/edit/${id}`)
}

export async function save(values) {
  return postRequest(`${API_URL_INVENTORY}inventoryClassCostType/save`, values)
}

export function remove(values) {
  return postRequest(`${API_URL_INVENTORY}inventoryClassCostType/delete`, values)
}

export async function list2(
  size,
  index,
  searchTerm,
  orderBy,
  inventoryClassCode,
  costTypeCode,
  definitionCode,
  appropriation,
  code,
) {
  const contractID = getcontractIdNumber()
  const url = `${API_URL_INVENTORY}inventoryClassCostType/get-list2/${size || '15'}/${index ||
    '0'}/${searchTerm || '%20'}/${orderBy || '%20'}/${contractID}/${inventoryClassCode ||
    '%20'}/${costTypeCode || '%20'}/${definitionCode || '%20'}/${appropriation || '%20'}/${code ||
    '%20'}`
  return getRequest(url)
}

export async function importData(values) {
  console.log('importData', values)
  const headers = {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
  }
  return postRequest(`${API_URL_INVENTORY}inventoryClassCostType/import`, values, headers)
}
