import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove } from '../../services/proposalTravelCost'

function* fetchProposalTravelCostsRefresh() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list)
  yield put({
    type: actions.LIST_PROPOSALTRAVELCOST,
    result,
  })
}

function* fetchProposalTravelCosts() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list)
  yield put({
    type: actions.LIST_PROPOSALTRAVELCOST,
    result,
  })
}

function* fetchProposalTravelCostEdit({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  yield put({
    type: actions.EDIT_PROPOSALTRAVELCOST,
    result,
  })
}

function* fetchProposalTravelCostSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SAVE_PROPOSALTRAVELCOST_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_PROPOSALTRAVELCOSTS,
  })
}

function* fetchProposalTravelCostDelete({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, id)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_PROPOSALTRAVELCOST_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_PROPOSALTRAVELCOSTS,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_PROPOSALTRAVELCOSTS, fetchProposalTravelCosts),
    takeEvery(actions.FETCH_PROPOSALTRAVELCOST, fetchProposalTravelCostEdit),
    takeEvery(actions.SAVE_PROPOSALTRAVELCOST, fetchProposalTravelCostSave),
    takeEvery(actions.DELETE_PROPOSALTRAVELCOST, fetchProposalTravelCostDelete),
    takeEvery(actions.RE_FETCH_PROPOSALTRAVELCOSTS, fetchProposalTravelCostsRefresh),
  ])
}
