import { getRequest, postRequest, getContractPartnerID } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractPartnerID'))
    searchTerm = `${searchTerm}, ContractPartnerID:${getContractPartnerID()},`
  return getRequest(`contractPartnerPerson/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`contractPartnerPerson/edit/${id}`)
}

export async function save(values) {
  return postRequest(`contractPartnerPerson/save`, values)
}

export function remove(values) {
  return postRequest(`contractPartnerPerson/delete`, values)
}
