/*eslint-disable */

import React from 'react'
import { connect } from 'react-redux'
import { accessMenu } from 'role'
import { Link } from 'react-router-dom'

import MenuContract from './MenuPagesContract'
import MenuReport from './MenuPagesReports'
import MenuBasicData from './MenuPagesBasicData'
import MenuSetting from './MenuPagesSetting'
import LanguageSwitcher from './LanguageSwitcher'

import UserMenu from './UserMenu'
import style from './style.module.scss'

@connect(({ contracts, user, companys }) => ({ contracts, user, companys }))
class TopBarDark extends React.Component {
  render() {
    const { contracts, user } = this.props
    // console.log(user.role)
    return (
      <div className={`${style.topbarDark} py-0`}>
        <Link to="/contracts" className={`${style.topbarDark__logo} d-md-block topbarDarkLogo`}>
          <img
            src="/resources/images/protec-logo.png"
            alt="softlare"
            style={{ width: 130, marginRight: 10 }}
          />
          <div
            className={`${style.topbarDark__logo__descr} d-none d-lg-block topbarDarkLogo`}
            style={{ marginTop: 8 }}
          >
            Contract Management System
          </div>
        </Link>

        {(user.role === 'admin' || (user.role !== 'admin' && accessMenu('basicdata'))) && (
          <div className="mr-3 d-block">
            <MenuBasicData disabled={!contracts.selected} />
          </div>
        )}

        {(user.role === 'admin' || (user.role !== 'admin' && accessMenu('contract'))) && (
          <div className="mr-3 d-block">
            <MenuContract disabled={!contracts.selected} />
          </div>
        )}

        {(user.role === 'admin' || (user.role !== 'admin' && accessMenu('reports'))) && (
          <div className="mr-3 d-block">
            <MenuReport disabled={!contracts.selected} />
          </div>
        )}

        {user.role === 'admin' && (
          <div className="mr-3 d-block">
            <MenuSetting />
          </div>
        )}

        {/* <div className="mb-0 mr-4 d-xl-block d-none"><Status /></div> */}
        <div className="ml-auto mr-4 d-md-block">
          <LanguageSwitcher />
        </div>
        {/* <div className="mr-4 d-none d-md-block"><Actions /></div> */}
        <div className="d-md-block">
          <UserMenu />
        </div>
      </div>
    )
  }
}

export default TopBarDark
