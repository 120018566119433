import { getRequest, postRequest, deleteRequest, getcontractIdNumber } from './api'
import { API_URL_INVENTORY } from '../constants/index'

export async function list(size, index, searchTerm, orderBy) {
  console.log('fetchMainInventoryList', size, index, searchTerm, orderBy)
  if (searchTerm === null) {
    searchTerm = `ContractID:${getcontractIdNumber()}`
  } else {
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
  }
  console.log('fetchMainInventoryList searchTerm', searchTerm)

  const urlParameters = new URLSearchParams({
    size: size || 15,
    index: index || 0,
    query: searchTerm || null,
    order: orderBy || null,
  })
  console.log('fetchMainInventoryList urlParameters', urlParameters)
  return getRequest(`${API_URL_INVENTORY}mainInventory?${urlParameters}`)
}

export async function find(id) {
  return getRequest(`${API_URL_INVENTORY}mainInventory/${id}`)
}

export async function save(values) {
  return postRequest(`${API_URL_INVENTORY}mainInventory`, values)
}

export function remove(id) {
  console.log('remove', id)
  return deleteRequest(`${API_URL_INVENTORY}mainInventory/${id}`)
}
