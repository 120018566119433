const actions = {
  REQUESTING: 'CostTypes/REQUESTING',
  RESPONSE_ERROR: 'CostTypes/RESPONSE_ERROR',

  FETCH_COSTTYPES: 'CostTypes/FETCH',
  LIST_COSTTYPE: 'CostType/LIST',

  FETCH_COSTTYPE: 'CostType/FETCH',
  NEW_COSTTYPE: 'CostType/NEW',
  EDIT_COSTTYPE: 'CostType/EDIT',
  RESET_COSTTYPE: 'CostType/RESET',

  RE_FETCH_COSTTYPES: 'CostTypes/REFRESH',
  SAVE_COSTTYPE: 'CostType/SAVE',
  SAVE_COSTTYPE_RECIEVED: 'CostType/SAVE_COSTTYPE_RECIEVED',

  DELETE_COSTTYPE: 'CostType/DELETE',
  DELETE_COSTTYPE_RECIEVED: 'CostType/DELETE_RECIEVED',

  IMPORT_COSTTYPE: 'CostType/IMPORT',
}

export default actions
