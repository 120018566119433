import actions from './actions'

export default function ContractYearsReducer(state = { loading: false }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_CONTRACTYEAR:
      console.log('LIST_CONTRACTYEAR', action)
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.EDIT_CONTRACTYEAR:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
        openModal: action.openModal,
      }
    case actions.RESET_CONTRACTYEAR:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
        openModal: null,
      }
    case actions.NEW_CONTRACTYEAR:
      console.log('NEW_CONTRACTYEAR')
      return {
        ...state,
        editing: { contractYearID: 0 },
        loading: false,
        saveSuccess: false,
        openModal: true,
      }
    case actions.CREATE_CONTRACTYEAR_RECIEVED ||
      actions.UPDATE_CONTRACTYEAR_RECIEVED ||
      actions.DELETE_CONTRACTYEAR_RECIEVED:
      return {
        ...state,
        editing: { contractYearID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    case actions.SAVE_CONTRACTYEAR_RECIEVED:
      console.log('SAVE_CONTRACTYEAR_RECIEVED', action)
      return {
        ...state,
        loading: false,
        saveSuccess: true,
        message: action.message,
        savedContractYearID: action.result.value,
      }
    default:
      return state
  }
}
