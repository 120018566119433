const actions = {
  REQUESTING: 'WorkOrderStatusTables/REQUESTING',
  RESPONSE_ERROR: 'WorkOrderStatusTables/RESPONSE_ERROR',

  FETCH_WORKORDERSTATUSTABLES: 'WorkOrderStatusTables/FETCH',
  LIST_WORKORDERSTATUSTABLE: 'WorkOrderStatusTable/LIST',

  FETCH_WORKORDERSTATUSTABLE: 'WorkOrderStatusTable/FETCH',
  NEW_WORKORDERSTATUSTABLE: 'WorkOrderStatusTable/NEW',
  EDIT_WORKORDERSTATUSTABLE: 'WorkOrderStatusTable/EDIT',
  RESET_WORKORDERSTATUSTABLE: 'WorkOrderStatusTable/RESET',

  RE_FETCH_WORKORDERSTATUSTABLES: 'WorkOrderStatusTables/REFRESH',
  SAVE_WORKORDERSTATUSTABLE: 'WorkOrderStatusTable/SAVE',
  SAVE_WORKORDERSTATUSTABLE_RECIEVED: 'WorkOrderStatusTable/SAVE_WORKORDERSTATUSTABLE_RECIEVED',

  DELETE_WORKORDERSTATUSTABLE: 'WorkOrderStatusTable/DELETE',
  DELETE_WORKORDERSTATUSTABLE_RECIEVED: 'WorkOrderStatusTable/DELETE_RECIEVED',
}

export default actions
