import { store, history } from 'index'

const operatorPages = [
  'companys',
  'companies',
  'projects',
  'companyAddresss',

  'contracts',
  'partTransfers',
  'inventories',
  'inventoryLogs',
  'inventorys',
  'inventoryProductInformations',
  'workorders',
  'workorderlist',
  'workOrderEdit',
  'parts',
  'inventoryClass',
  'technicians',
  'inventory',
  'inventoryServiceLabors',

  'reportworkorder',
  'reportsworkorders',
  'reportsinventory',
  'reporttechnician',
  'reportstatus',
  'reportinventory',
  'reportsinventories',
  'completedworkorder',
]

const customerPages = [
  'companys',
  'companies',
  'projects',

  'contracts',
  'workOrderType',
  'workOrderTypes',
  'workordertype',
  'workordertypes',
  'inventoryServices',
  'inventoryClass',
  'workOrderEdit',

  'inventories',
  'inventorys',
  'inventoryProductInformations',
  'workorders',
  'workorderlist',
  'inventoryclass',
  'parts',
  'inventory',
  'inventoryServiceLabors',
  'areas',
  'address',
  'locations',
  'repairLimits',
  'inventoryclasses',
]

const technicianPages = [
  'companys',
  'companies',
  'projects',
  'companyAddresss',

  'inventories',
  'inventoryLogs',
  'inventorys',
  'inventoryProductInformations',
  'workorders',
  'workorderlist',
  'workOrderEdit',
  'parts',
  'inventoryClass',
  'technicians',
  'inventory',
  'inventoryServiceLabors',
  'reportworkorder',
  'reportsworkorders',
  'reportsinventory',
  'reporttechnician',
  'reportstatus',
  'reportinventory',
  'reportsinventories',
  'completedworkorder',
  'technicianDailyReports',
  'technicianDailyRecords',
  'partTransfers',
]

// General Data
// -	Order types
// -	Asset services

// Contract
// -	Work Order list
// -	Assets
// -	Asset Class
// -	Parts
// -	MLFB
// -	Labours
// -	Technicians
// -	Clins
// -	Cost Type
// -	Adress
// -	Repair limits

// Reports
// -	Work Order
// -	Assets
// -	Daily Status Report
// -	Completed Work Order

const menus = ['basicdata', 'contract', 'reports']

export const accessPage = page => {
  let roleName = ''
  let roleName2 = ''
  let userName = ''

  const state = store.getState()

  if (state && state.user && state.user.role) {
    roleName = state.user.role
  }

  if (state && state.user && state.user.role2) {
    roleName2 = state.user.role2
  }

  if (state && state.user && state.user.userName) {
    userName = state.user.userName
  }

  if (page === 'mlfbDocumentsFolder') {
    if (userName !== 'admin') {
      return false
    }
  }

  if (page === 'partTransfers' || page === 'partOrders') {
    return false
  }

  if (roleName === 'admin') {
    return true
  }

  if (roleName2 === 'admin') {
    return true
  }

  if (roleName === 'operator') {
    try {
      for (let i = 0; i <= operatorPages.length; i += 1) {
        if (
          operatorPages[i] !== undefined &&
          operatorPages[i] !== null &&
          operatorPages[i].includes(page)
        ) {
          return true
        }
      }
    } catch (e) {
      console.log(e)
      return false
    }
  }

  if (roleName === 'customer') {
    try {
      for (let i = 0; i <= customerPages.length; i += 1) {
        if (
          customerPages[i] !== undefined &&
          customerPages[i] !== null &&
          customerPages[i].includes(page)
        ) {
          return true
        }
      }
    } catch (e) {
      console.log(e)
      return false
    }
  }

  if (roleName === 'technician') {
    try {
      for (let i = 0; i <= technicianPages.length; i += 1) {
        if (
          technicianPages[i] !== undefined &&
          technicianPages[i] !== null &&
          technicianPages[i].includes(page)
        ) {
          return true
        }
      }
    } catch (e) {
      console.log(e)
      return false
    }
  }

  return false
}

export const accessMenu = menu => {
  let roleName = ''
  const state = store.getState()
  if (state && state.user && state.user.role) {
    roleName = state.user.role
  }

  if (roleName === 'admin') {
    return true
  }
  if (roleName !== 'admin') {
    try {
      for (let i = 0; i <= menus.length; i += 1) {
        if (roleName === 'customer' && menus[i] === 'reports') {
          return false
        }
        if (menus[i] !== undefined && menus[i] !== null && menus[i].includes(menu)) {
          return true
        }
      }
    } catch (e) {
      console.log(e)
      return false
    }
  }
  return false
}

export const redirectRole = page => {
  const re = accessPage(page)
  if (re === false) {
    history.push('/system/403')
  }
}
