import { getRequest, postRequest, getcontractIdNumber } from './api'
import { API_URL_INVENTORY } from '../constants/index'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID')) {
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
  }

  return getRequest(
    `${API_URL_INVENTORY}costType/get-list2/${size || 15}/${index || 0}/${searchTerm ||
      '%20'}/${orderBy || '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`${API_URL_INVENTORY}costType/edit/${id}`)
}

export async function save(values) {
  return postRequest(`${API_URL_INVENTORY}costType/save`, values)
}

export function remove(values) {
  return postRequest(`${API_URL_INVENTORY}costType/delete`, values)
}

export async function importData(values) {
  console.log('importData', values)
  const headers = {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
  }
  return postRequest(`${API_URL_INVENTORY}costType/import`, values, headers)
}
