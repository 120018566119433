const actions = {
  REQUESTING: 'InventoryClasss/REQUESTING',
  RESPONSE_ERROR: 'InventoryClasss/RESPONSE_ERROR',

  FETCH_INVENTORYCLASSS: 'InventoryClasss/FETCH',
  LIST_INVENTORYCLASS: 'InventoryClass/LIST',

  FETCH_INVENTORYCLASS: 'InventoryClass/FETCH',
  NEW_INVENTORYCLASS: 'InventoryClass/NEW',
  EDIT_INVENTORYCLASS: 'InventoryClass/EDIT',
  RESET_INVENTORYCLASS: 'InventoryClass/RESET',

  RE_FETCH_INVENTORYCLASSS: 'InventoryClasss/REFRESH',
  SAVE_INVENTORYCLASS: 'InventoryClass/SAVE',
  SAVE_INVENTORYCLASS_RECIEVED: 'InventoryClass/SAVE_INVENTORYCLASS_RECIEVED',

  DELETE_INVENTORYCLASS: 'InventoryClass/DELETE',
  DELETE_INVENTORYCLASS_RECIEVED: 'InventoryClass/DELETE_RECIEVED',

  IMPORT_INVENTORYCLASS: 'InventoryClass/IMPORT',
}

export default actions
