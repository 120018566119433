import actions from './actions'

export default function MainInventoryReducer(state = { loading: false, lists: null }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }
    case actions.LIST_MAININVENTORY:
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }
    case actions.LIST_INVENTORYLIST_BY_MAININVENTORYID:
      return {
        ...state,
        mainInventoryNumber: action.mainInventoryNumber,
        inventoryList: action.inventoryList,
        IsOpenInventoryListModal: true,
        loading: false,
      }
    case actions.CLOSE_INVENTORYLIST_BY_MAININVENTORYID_POPUP:
      return {
        ...state,
        inventoryList: [],
        IsOpenInventoryListModal: false,
        loading: false,
      }
    case actions.EDIT_MAININVENTORY:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
        openModal: action.openModal,
      }
    case actions.RESET_MAININVENTORY:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
        openModal: null,
      }
    case actions.NEW_MAININVENTORY:
      return {
        ...state,
        openModal: true,
        editing: { mainInventoryID: 0 },
        loading: false,
        saveSuccess: false,
      }

    case actions.CREATE_MAININVENTORY_RECIEVED ||
      actions.UPDATE_MAININVENTORY_RECIEVED ||
      actions.DELETE_MAININVENTORY_RECIEVED:
      return {
        ...state,
        editing: { mainInventoryID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }
    case actions.RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    case actions.SAVE_MAININVENTORY_RECIEVED: {
      return {
        ...state,
        loading: false,
        saveSuccess: true,
        message: action.message,
        savedMainInventoryID: action.result.value,
      }
    }
    default:
      return state
  }
}
