import actions from './actions'

export default function LocationsReducer(state = { loading: false, lists: null }, action) {
  switch (action.type) {
    case actions.REQUESTING:
      console.log('REQUESTING location')
      return {
        ...state,
        loading: true,
        editing: null,
        saveSuccess: false,
      }

    case actions.LIST_LOCATION:
      console.log('LIST_LOCATION location')
      return {
        ...state,
        result: action.result,
        loading: false,
        saveSuccess: false,
      }

    case actions.FILTER_LOCATIONS_RECIEVED:
      return {
        ...state,
        lists: action.result,
        loading: false,
        saveSuccess: false,
      }

    case actions.EDIT_LOCATION:
      return {
        ...state,
        editing: action.result,
        loading: false,
        saveSuccess: false,
        openModal: action.openModal,
      }

    case actions.RESET_LOCATION:
      return {
        ...state,
        editing: null,
        loading: false,
        saveSuccess: false,
        lists: null,
        openModal: null,
      }

    case actions.NEW_LOCATION:
      return {
        ...state,
        openModal: true,
        editing: { locationID: 0 },
        loading: false,
        saveSuccess: false,
      }

    case actions.CREATE_LOCATION_RECIEVED ||
      actions.UPDATE_LOCATION_RECIEVED ||
      actions.DELETE_LOCATION_RECIEVED:
      return {
        ...state,
        editing: { locationID: 0 },
        loading: false,
        saveSuccess: true, // mark
      }

    case actions.RESPONSE_ERROR:
      console.log('RESPONSE_ERROR location')
      return {
        ...state,
        loading: false,
        error: true,
        saveSuccess: false,
        message: action.message,
      }
    case actions.SAVE_LOCATION_RECIEVED: {
      console.log('SAVE_LOCATION_RECIEVED location', action)
      return {
        ...state,
        loading: false,
        saveSuccess: true,
        message: action.message,
        savedLocationID: action.result.value,
      }
    }
    default:
      return state
  }
}
