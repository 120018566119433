const actions = {
  REQUESTING: 'ContractYears/REQUESTING',
  RESPONSE_ERROR: 'ContractYears/RESPONSE_ERROR',

  FETCH_CONTRACTYEARS: 'ContractYears/FETCH',
  LIST_CONTRACTYEAR: 'ContractYear/LIST',

  FETCH_CONTRACTYEAR: 'ContractYear/FETCH',
  NEW_CONTRACTYEAR: 'ContractYear/NEW',
  EDIT_CONTRACTYEAR: 'ContractYear/EDIT',
  RESET_CONTRACTYEAR: 'ContractYear/RESET',

  RE_FETCH_CONTRACTYEARS: 'ContractYears/REFRESH',
  SAVE_CONTRACTYEAR: 'ContractYear/SAVE',
  SAVE_CONTRACTYEAR_RECIEVED: 'ContractYear/SAVE_CONTRACTYEAR_RECIEVED',

  DELETE_CONTRACTYEAR: 'ContractYear/DELETE',
  DELETE_CONTRACTYEAR_RECIEVED: 'ContractYear/DELETE_RECIEVED',
}

export default actions
