import actions from './actions'

export default function userReducer(state = { loading: true }, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        menuData: action.payload,
        loading: false,
      }

    case actions.SET_CONTRACT_MENU:
      return {
        ...state,
        menuContract: action.payload,
        loading: false,
      }

    // parametrick
    case actions.GET_MENU_FOR_CONTRACT_RECEIVED:
      return {
        ...state,
        menuContract: action.payload,
        loading: false,
      }

    case actions.SET_REPORT_MENU:
      return {
        ...state,
        menuReport: action.payload,
        loading: false,
      }

    case actions.SET_MENUBASICDATA:
      return {
        ...state,
        menuBasicData: action.payload,
        loading: false,
      }

    case actions.SET_SETTING_MENU:
      return {
        ...state,
        menuData5: action.payload,
        loading: false,
      }

    default:
      return state
  }
}
