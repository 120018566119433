const actions = {
  REQUESTING: 'ContractPartnerPersons/REQUESTING',
  RESPONSE_ERROR: 'ContractPartnerPersons/RESPONSE_ERROR',

  FETCH_CONTRACTPARTNERPERSONS: 'ContractPartnerPersons/FETCH',
  LIST_CONTRACTPARTNERPERSON: 'ContractPartnerPerson/LIST',

  FETCH_CONTRACTPARTNERPERSON: 'ContractPartnerPerson/FETCH',
  NEW_CONTRACTPARTNERPERSON: 'ContractPartnerPerson/NEW',
  EDIT_CONTRACTPARTNERPERSON: 'ContractPartnerPerson/EDIT',
  RESET_CONTRACTPARTNERPERSON: 'ContractPartnerPerson/RESET',

  RE_FETCH_CONTRACTPARTNERPERSONS: 'ContractPartnerPersons/REFRESH',
  SAVE_CONTRACTPARTNERPERSON: 'ContractPartnerPerson/SAVE',
  SAVE_CONTRACTPARTNERPERSON_RECIEVED: 'ContractPartnerPerson/SAVE_CONTRACTPARTNERPERSON_RECIEVED',

  DELETE_CONTRACTPARTNERPERSON: 'ContractPartnerPerson/DELETE',
  DELETE_CONTRACTPARTNERPERSON_RECIEVED: 'ContractPartnerPerson/DELETE_RECIEVED',
}

export default actions
