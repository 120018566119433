import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC } from '../../constants'
import {
  list,
  find,
  save,
  createWorkOrderByProposal,
  createWorkorders,
  skipWorkorders,
  get,
  create,
  recall,
  getWorkOrders2,
  saveAsset,
  savePdf,
  nextControl,
  costView,
  getAppointmenDate,
  generalCostView,
  createWithAsset,
  listByMainInventoryID,
} from '../../services/workOrder'

import { getAssetTecReport, getAssetTecCount } from '../../services/report'

function* fetchWorkOrdersRefresh() {
  let fe = JSON.parse(localStorage.getItem('fetchWorkOrders'))
  if (fe == null) {
    fe = {}
    fe.size = localStorage.getItem('pageSize') || 15
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  } else {
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  yield put({
    type: actions.LIST_WORKORDER,
    result,
  })
}

function* fetchWorkOrders({ size, index, searchTerm, orderBy }) {
  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = null // JSON.parse(localStorage.getItem('fetchWorkOrders'))
    if (fe != null) {
      size = fe.size || localStorage.getItem('pageSize') || 15
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = size || localStorage.getItem('pageSize') || 15
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem('fetchWorkOrders', JSON.stringify({ size, index, searchTerm, orderBy }))
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  yield put({
    type: actions.LIST_WORKORDER,
    result,
  })
}

function* fetchWorkOrderEdit({ id }) {
  console.log('fetchWorkOrderEdit', id)
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id)
  console.log('fetchWorkOrderEdit result', result)
  yield put({
    type: actions.EDIT_WORKORDER,
    result,
  })
}

function* fetchNextControls({ contractId, startDate, endDate }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(nextControl, contractId, startDate, endDate)
  yield put({
    type: actions.FETCH_NEXTCONTROLS_RECIEVED,
    result,
  })
}

function* fetchWorkOrderSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const id = yield call(save, values)
  if (id !== 0) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  const result = yield call(get, id)
  yield put({
    type: actions.SAVE_WORKORDER_RECIEVED,
    result,
  })
}

function* fetchCreateWorkOrderByProposal({ proposalID }) {
  console.log('fetchCreateWorkOrderByProposal', proposalID)

  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(createWorkOrderByProposal, proposalID)
  console.log('fetchCreateWorkOrderByProposal result', result)
  if (result.status === 1) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  } else {
    notification.error({ message: '', description: result.message })
  }
  yield put({
    type: actions.CREATE_WORKORDER_BY_PROPOSAL_RECIEVED,
    result,
  })
}

function* createAutoWorkorders({ values, contractId, startDate, endDate }) {
  yield put({
    type: actions.REQUESTING,
  })

  const result2 = yield call(createWorkorders, values, startDate, endDate)
  console.log(result2)
  if (result2.success === true) {
    notification.success({ message: SAVE_200_MSG, description: 'Work orders created.' })
  } else {
    notification.error({
      message: SAVE_200_MSG,
      description: 'There are some problems in your request please contact with Admininstrator.',
    })
  }

  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(nextControl, contractId, startDate, endDate)
  yield put({
    type: actions.FETCH_NEXTCONTROLS_RECIEVED,
    result,
  })
}

function* skipAutoWorkorders(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const id = yield call(skipWorkorders, values)
  if (id !== 0) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
  }
  yield put({
    type: actions.SKIP_AUTO_WORKORDERS,
  })
}

function* createNewWorkorder() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(create)
  yield put({
    type: actions.CREATE_NEW_WORKORDER_RECIEVED,
    result,
  })
}

function* createNewWorkOrderWithAsset({ inventoryID }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(createWithAsset, inventoryID)
  yield put({
    type: actions.CREATE_NEW_WORKORDER_RECIEVED,
    result,
  })
}

function* resetNewWorkorder() {
  yield put({
    type: actions.CREATE_NEW_WORKORDER_RESET_RECIEVED,
  })
}

function* assetHistory({ inventoryid }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getAssetTecReport, inventoryid)
  yield put({
    type: actions.FETCH_WORKORDER_ASSET_REPORT_RECIEVED,
    result,
  })
}

function* assetHistoryReset() {
  yield put({
    type: actions.FETCH_WORKORDER_ASSET_REPORT_RESET_RECIEVED,
  })
}

function* assetHistoryCount({ inventoryid, workOrderID }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getAssetTecCount, inventoryid, workOrderID)
  yield put({
    type: actions.FETCH_WORKORDER_ASSET_COUNT_RECIEVED,
    result,
  })
}

function* recallWorkorder({ workOrderID, orderNumber }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(recall, workOrderID, orderNumber)
  yield put({
    type: actions.RECALL_WORKORDER_RECIEVED,
    result,
  })
}

function* fetchWorkOrderFilter({
  size,
  index,
  searchTerm,
  orderBy,
  completedDate,
  orderNumber,
  workOrderStatus,
  subStatus,
  mainInventoryNumber,
  inventoryNumber,
  workOrderType,
  areaID,
  locationID,
  address,
  callerName,
  trackingNumber,
  partNumbers,
  technicianNames,
}) {
  const t = localStorage.getItem('workOrderListFetch')
  if (t !== undefined && t != null) {
    const fe = JSON.parse(t)
    if (fe != null) {
      if (size === undefined) size = fe.size || size
      if (index === undefined) index = fe.index || index
      if (searchTerm === undefined) searchTerm = fe.searchTerm || searchTerm
      if (orderBy === undefined) orderBy = fe.orderBy || orderBy
      workOrderStatus = fe.workOrderStatus || workOrderStatus
      subStatus = fe.subStatus || subStatus
      locationID = fe.locationID || locationID
      areaID = fe.areaID || areaID
      address = fe.address || address
      orderNumber = fe.orderNumber || orderNumber
      completedDate = fe.completedDate || completedDate
      workOrderType = fe.workOrderType || workOrderType
      mainInventoryNumber = fe.mainInventoryNumber || mainInventoryNumber
      inventoryNumber = fe.inventoryNumber || inventoryNumber
      callerName = fe.callerName || callerName
      trackingNumber = fe.trackingNumber || trackingNumber
      partNumbers = fe.partNumbers || partNumbers
      technicianNames = fe.technicianNames || technicianNames
    }
  }
  size = size || localStorage.getItem('pageSize') || 15
  try {
    localStorage.setItem(
      'workOrderListFetch',
      JSON.stringify({
        size,
        index,
        searchTerm,
        orderBy,
        completedDate,
        orderNumber,
        workOrderStatus,
        subStatus,
        mainInventoryNumber,
        inventoryNumber,
        workOrderType,
        areaID,
        locationID,
        address,
        callerName,
        trackingNumber,
        partNumbers,
        technicianNames,
      }),
    )
  } catch (e) {
    console.log(e)
  }

  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(
    getWorkOrders2,
    size,
    index,
    searchTerm,
    orderBy,
    completedDate,
    orderNumber,
    workOrderStatus,
    subStatus,
    mainInventoryNumber,
    inventoryNumber,
    workOrderType,
    areaID,
    locationID,
    address,
    callerName,
    trackingNumber,
    partNumbers,
    technicianNames,
  )
  yield put({
    type: actions.LIST_WORKORDER,
    result,
  })
}

function* SaveAsset(values) {
  yield put({
    type: actions.REQUESTING,
  })
  yield call(saveAsset, values)
  const result = yield call(find, values.values.workOrderID)
  yield put({
    type: actions.EDIT_WORKORDER,
    result,
  })
}

function* SavePdf({ workOrderID, file }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(savePdf, workOrderID, file)
  yield put({
    type: actions.SAVEPDF_RECIEVED,
    result,
  })
}

function* fetchCostViews({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(costView, id)
  yield put({
    type: actions.FETCH_WORKORDER_COSTVIEW_RECIEVED,
    result,
  })
}

function* printPopup() {
  yield put({
    type: actions.PRINT_POPUP_RECIEVED,
  })
}

function* printPopupReset() {
  yield put({
    type: actions.PRINT_POPUP_RESET_RECIEVED,
  })
}

function* budgetPopup() {
  yield put({
    type: actions.POPUP_BUDGET_RECIEVED,
  })
}

function* budgetPopupReset() {
  yield put({
    type: actions.POPUP_BUDGET_RESET_RECIEVED,
  })
}

function* openPopupCustomerSignature() {
  yield put({
    type: actions.OPEN_POPUP_CUSTOMER_SIGNATURE_RECIEVED,
  })
}

function* closePopupCustomerSignature() {
  yield put({
    type: actions.CLOSE_POPUP_CUSTOMER_SIGNATURE_RECIEVED,
  })
}

function* openPopupTechnicianWork() {
  yield put({
    type: actions.OPEN_POPUP_TECHNICIAN_WORK_RECIEVED,
  })
}

function* closePopupTechnicianWork() {
  yield put({
    type: actions.CLOSE_POPUP_TECHNICIAN_WORK_RECIEVED,
  })
}

function* openPopupServiceOrderFile() {
  yield put({
    type: actions.OPEN_POPUP_SERVICE_ORDER_FILE_RECIEVED,
  })
}

function* closePopupServiceOrderFile() {
  yield put({
    type: actions.CLOSE_POPUP_SERVICE_ORDER_FILE_RECIEVED,
  })
}

function* openPopupWorkOrderCostSummary() {
  yield put({
    type: actions.OPEN_POPUP_WORKORDER_COST_SUMMARY_RECIEVED,
  })
}

function* closePopupWorkOrderCostSummary() {
  yield put({
    type: actions.CLOSE_POPUP_WORKORDER_COST_SUMMARY_RECIEVED,
  })
}

function* openPopupWorkOrderProposalInfo() {
  yield put({
    type: actions.OPEN_POPUP_WORKORDER_PROPOSAL_INFO_RECIEVED,
  })
}

function* closePopupWorkOrderProposalInfo() {
  yield put({
    type: actions.CLOSE_POPUP_WORKORDER_PROPOSAL_INFO_RECIEVED,
  })
}

function* openPopupWorkOrderEmailSend() {
  yield put({
    type: actions.OPEN_POPUP_WORKORDER_EMAIL_SEND_RECIEVED,
  })
}

function* closePopupWorkOrderEmailSend() {
  yield put({
    type: actions.CLOSE_POPUP_WORKORDER_EMAIL_SEND_RECIEVED,
  })
}

function* openPopupWorkOrderAttachment() {
  yield put({
    type: actions.OPEN_POPUP_WORKORDER_ATTACHMENT_RECIEVED,
  })
}

function* closePopupWorkOrderAttachment() {
  yield put({
    type: actions.CLOSE_POPUP_WORKORDER_ATTACHMENT_RECIEVED,
  })
}

function* openPopupWorkOrderListByMainInventory() {
  yield put({
    type: actions.OPEN_POPUP_WORKORDERLISTBYMAININVENTORY_RECIEVED,
  })
}

function* closePopupWorkOrderListByMainInventory() {
  yield put({
    type: actions.CLOSE_POPUP_WORKORDERLISTBYMAININVENTORY_RECIEVED,
  })
}

function* fetchAppointmenDate({ id }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getAppointmenDate, id)
  yield put({
    type: actions.GET_APPOINTMENT_DATE_RECIEVED,
    payload: result,
  })
}
function* resetAppointmenDate() {
  yield put({
    type: actions.GET_APPOINTMENT_DATE_RESET_RECIEVED,
  })
}

function* fetchGeneralCostView({ id }) {
  if (id > 0) {
    yield put({
      type: actions.REQUESTING,
    })
    const result = yield call(generalCostView, id)
    yield put({
      type: actions.GET_COSTVIEW_RECIVEVED2,
      payload: result,
    })
  }
}

function* addInventoryCatch({ id }) {
  console.log('asda', id)
  yield put({
    type: actions.ADD_INVENTORY_RECIEVED,
    payload: id,
  })
}

function* addInventoryCatchReset() {
  yield put({
    type: actions.ADD_INVENTORY_RESET_RECIEVED,
  })
}

function* fetchWorkOrderListByMainInventory({ id }) {
  if (id > 0) {
    yield put({
      type: actions.REQUESTING,
    })
    const result = yield call(listByMainInventoryID, id)
    yield put({
      type: actions.GET_WORKORDERLISTBYMAININVENTORY_RECIEVED,
      payload: result,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_APPOINTMENT_DATE, fetchAppointmenDate),
    takeEvery(actions.GET_APPOINTMENT_DATE_RESET, resetAppointmenDate),
    takeEvery(actions.GET_COSTVIEW2, fetchGeneralCostView),

    takeEvery(actions.PRINT_POPUP, printPopup),
    takeEvery(actions.PRINT_POPUP_RESET, printPopupReset),

    takeEvery(actions.POPUP_BUDGET, budgetPopup),
    takeEvery(actions.POPUP_BUDGET_RESET, budgetPopupReset),

    takeEvery(actions.OPEN_POPUP_CUSTOMER_SIGNATURE, openPopupCustomerSignature),
    takeEvery(actions.CLOSE_POPUP_CUSTOMER_SIGNATURE, closePopupCustomerSignature),

    takeEvery(actions.OPEN_POPUP_TECHNICIAN_WORK, openPopupTechnicianWork),
    takeEvery(actions.CLOSE_POPUP_TECHNICIAN_WORK, closePopupTechnicianWork),

    takeEvery(actions.OPEN_POPUP_SERVICE_ORDER_FILE, openPopupServiceOrderFile),
    takeEvery(actions.CLOSE_POPUP_SERVICE_ORDER_FILE, closePopupServiceOrderFile),

    takeEvery(actions.OPEN_POPUP_WORKORDER_COST_SUMMARY, openPopupWorkOrderCostSummary),
    takeEvery(actions.CLOSE_POPUP_WORKORDER_COST_SUMMARY, closePopupWorkOrderCostSummary),

    takeEvery(actions.OPEN_POPUP_WORKORDER_PROPOSAL_INFO, openPopupWorkOrderProposalInfo),
    takeEvery(actions.CLOSE_POPUP_WORKORDER_PROPOSAL_INFO, closePopupWorkOrderProposalInfo),

    takeEvery(actions.OPEN_POPUP_WORKORDER_EMAIL_SEND, openPopupWorkOrderEmailSend),
    takeEvery(actions.CLOSE_POPUP_WORKORDER_EMAIL_SEND, closePopupWorkOrderEmailSend),

    takeEvery(actions.OPEN_POPUP_WORKORDER_ATTACHMENT, openPopupWorkOrderAttachment),
    takeEvery(actions.CLOSE_POPUP_WORKORDER_ATTACHMENT, closePopupWorkOrderAttachment),

    takeEvery(actions.GET_WORKORDERLISTBYMAININVENTORY, fetchWorkOrderListByMainInventory),
    takeEvery(
      actions.OPEN_POPUP_WORKORDERLISTBYMAININVENTORY,
      openPopupWorkOrderListByMainInventory,
    ),
    takeEvery(
      actions.CLOSE_POPUP_WORKORDERLISTBYMAININVENTORY,
      closePopupWorkOrderListByMainInventory,
    ),

    takeEvery(actions.FETCH_WORKORDER_COSTVIEW, fetchCostViews),
    takeEvery(actions.FETCH_WORKORDERS, fetchWorkOrders),
    takeEvery(actions.FETCH_WORKORDER, fetchWorkOrderEdit),
    takeEvery(actions.SAVE_WORKORDER, fetchWorkOrderSave),
    takeEvery(actions.CREATE_WORKORDER_BY_PROPOSAL, fetchCreateWorkOrderByProposal),
    takeEvery(actions.RE_FETCH_WORKORDERS, fetchWorkOrdersRefresh),
    takeEvery(actions.CREATE_AUTO_WORKORDERS, createAutoWorkorders),
    takeEvery(actions.SKIP_AUTO_WORKORDERS_CATCH, skipAutoWorkorders),
    takeEvery(actions.CREATE_NEW_WORKORDER, createNewWorkorder),
    takeEvery(actions.CREATE_NEW_WORKORDER_RESET, resetNewWorkorder),
    takeEvery(actions.FETCH_WORKORDER_ASSET_REPORT, assetHistory),
    takeEvery(actions.FETCH_WORKORDER_ASSET_REPORT_RESET, assetHistoryReset),
    takeEvery(actions.FETCH_WORKORDER_ASSET_COUNT, assetHistoryCount),
    takeEvery(actions.RECALL_WORKORDER, recallWorkorder),
    takeEvery(actions.FETCH_WORKORDERS_FILTER, fetchWorkOrderFilter),
    takeEvery(actions.SAVE_ASSET, SaveAsset),
    takeEvery(actions.SAVEPDF, SavePdf),
    takeEvery(actions.FETCH_NEXTCONTROLS, fetchNextControls),
    takeEvery(actions.CREATE_NEW_WORKORDER_WITH_ASSET, createNewWorkOrderWithAsset),

    takeEvery(actions.ADD_INVENTORY, addInventoryCatch),
    takeEvery(actions.ADD_INVENTORY_RESET, addInventoryCatchReset),
  ])
}
