const actions = {
  REQUESTING: 'CompanyAddresss/REQUESTING',
  RESPONSE_ERROR: 'CompanyAddresss/RESPONSE_ERROR',

  FETCH_COMPANYADDRESSS: 'CompanyAddresss/FETCH',
  LIST_COMPANYADDRESS: 'CompanyAddress/LIST',

  FETCH_COMPANYADDRESS: 'CompanyAddress/FETCH',
  NEW_COMPANYADDRESS: 'CompanyAddress/NEW',
  EDIT_COMPANYADDRESS: 'CompanyAddress/EDIT',
  RESET_COMPANYADDRESS: 'CompanyAddress/RESET',

  RE_FETCH_COMPANYADDRESSS: 'CompanyAddresss/REFRESH',
  SAVE_COMPANYADDRESS: 'CompanyAddress/SAVE',
  SAVE_COMPANYADDRESS_RECIEVED: 'CompanyAddress/SAVE_COMPANYADDRESS_RECIEVED',

  DELETE_COMPANYADDRESS: 'CompanyAddress/DELETE',
  DELETE_COMPANYADDRESS_RECIEVED: 'CompanyAddress/DELETE_RECIEVED',
}

export default actions
