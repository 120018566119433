const actions = {
  REQUESTING: 'Inventorys/REQUESTING',
  RESPONSE_ERROR: 'Inventorys/RESPONSE_ERROR',

  FETCH_INVENTORYS: 'Inventorys/FETCH',
  FETCH_INVENTORYS_FILTER: 'Inventorys/FETCH_FILTER',
  LIST_INVENTORY: 'Inventory/LIST',

  FETCH_INVENTORY: 'Inventory/FETCH',
  NEW_INVENTORY: 'Inventory/NEW',
  EDIT_INVENTORY: 'Inventory/EDIT',
  RESET_INVENTORY: 'Inventory/RESET',
  RESET_INVENTORY_REC: 'RESET_INVENTORY_REC',

  RE_FETCH_INVENTORYS: 'Inventorys/REFRESH',
  SAVE_INVENTORY: 'Inventory/SAVE',
  SAVE_INVENTORY_RECIEVED: 'Inventory/SAVE_INVENTORY_RECIEVED',

  DELETE_INVENTORY: 'Inventory/DELETE',
  DELETE_INVENTORY_RECIEVED: 'Inventory/DELETE_RECIEVED',

  FETCH_MLFB: 'Inventory/FETCH_MLFB',
  FETCH_MLFB_REQ: 'Inventory/FETCH_MLFB_REQ',
  FETCH_MLFB_RECIEVED: 'Inventory/MLFB_RECIEVED',

  CONFIRM_MODAL_OPEN: 'Inventory/CONFIRM_MODAL_OPEN',
  CONFIRM_MODAL_RESET: 'Inventory/CONFIRM_MODAL_RESET',
  CONFIRM_CHOOSE_OK: 'Inventory/CONFIRM_CHOOSE_OK',

  CONFIRM_MODAL_OPEN_RECIEVER: 'Inventory/CONFIRM_MODAL_OPEN_RECIEVER',
  CONFIRM_MODAL_RESET_RECIEVER: 'Inventory/CONFIRM_MODAL_RESET_RECIEVER',
  CONFIRM_CHOOSE_OK_RECIEVER: 'Inventory/CONFIRM_CHOOSE_OK_RECIEVER',

  ADD_LOCATION: 'Inventory/ADD_LOCATION',
  ADD_LOCATION_RECIEVED: 'Inventory/ADD_LOCATION_RECIEVED',
  ADD_LOCATION_RESET: 'Inventory/ADD_LOCATION_RESET',
  ADD_LOCATION_RESET_RECIEVED: 'Inventory/ADD_LOCATION_RESET_RECIEVED',

  IMPORT_INVENTORY: 'Inventory/IMPORT',

  BULK_UPDATE_NEXT_PM_DATE: 'Inventory/BULK_UPDATE_NEXT_PM_DATE',
  BULK_UPDATE_NEXT_PM_DATE_RECIEVED: 'Inventory/BULK_UPDATE_NEXT_PM_DATE_RECIEVED',
  OPEN_BULK_UPDATE_NEXT_PM_DATE_MODAL: 'Inventory/OPEN_BULK_UPDATE_NEXT_PM_DATE_MODAL',
  CLOSE_BULK_UPDATE_NEXT_PM_DATE_MODAL: 'Inventory/CLOSE_BULK_UPDATE_NEXT_PM_DATE_MODAL',
}

export default actions
