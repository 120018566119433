const actions = {
  REQUESTING: 'Locations/REQUESTING',
  RESPONSE_ERROR: 'Locations/RESPONSE_ERROR',

  FETCH_LOCATIONS: 'Locations/FETCH',
  LIST_LOCATION: 'Location/LIST',

  FILTER_LOCATIONS: 'Locations/FILTER_LOCATIONS',
  FILTER_LOCATIONS_RECIEVED: 'Locations/FILTER_LOCATIONS_RECIEVED',

  FETCH_LOCATION: 'Location/FETCH',
  NEW_LOCATION: 'Location/NEW',
  EDIT_LOCATION: 'Location/EDIT',
  RESET_LOCATION: 'Location/RESET',

  RE_FETCH_LOCATIONS: 'Locations/REFRESH',
  SAVE_LOCATION: 'Location/SAVE',
  SAVE_LOCATION_RECIEVED: 'Location/SAVE_LOCATION_RECIEVED',

  DELETE_LOCATION: 'Location/DELETE',
  DELETE_LOCATION_RECIEVED: 'Location/DELETE_RECIEVED',

  FETCH_AREA: 'Locations/FETCH_AREA',
  FETCH_AREA_RECIEVED: 'LOCATIONS/FETCH_AREA_RECIEVED',
}

export default actions
