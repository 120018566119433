/*eslint-disable*/

import { all, put, call, takeEvery } from 'redux-saga/effects'

import getMenuData from 'services/menu/general'
import getSettingMenu from 'services/menu/setting'

import getBasicDataMenu from 'services/menu/basic'
import getContractMenu from 'services/menu/appType/general'
import generalReportMenu from 'services/menu/reports'

import actions from './actions'

export function* GET_DATA() {
  console.log('GET_DATA')
  const payload = yield call(getMenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload,
  })
}

export function* GET_CONTRACT_MENU() {
  console.log('GET_CONTRACT_MENU')
  const payload = yield call(getContractMenu)
  yield put({
    type: 'menu/SET_CONTRACT_MENU',
    payload,
  })
  const menuBasicData = yield call(getBasicDataMenu)
  yield put({
    type: 'menu/SET_MENUBASICDATA',
    payload: menuBasicData,
  })
}

export function* GET_REPORT_MENU() {
  console.log('GET_REPORT_MENU')
  const payload = yield call(generalReportMenu)
  yield put({
    type: 'menu/SET_REPORT_MENU',
    payload,
  })
}

export function* GET_BASICDATA_MENU() {
  console.log('GET_BASICDATA_MENU')
  const payload = yield call(getBasicDataMenu)
  yield put({
    type: 'menu/SET_MENUBASICDATA',
    payload,
  })
}

/// when we click the contract item on contract list, this method works
export function* getMenuForContract() {
  // console.log('getMenuForContract')
  // (gungoromer-20240621)We don't need this. We are using the same method for all menus
  // const payload = yield call(getContractMenu)
  // yield put({
  //   type: 'menu/SET_CONTRACT_MENU',
  //   payload,
  // })
  // const menu4 = yield call(getBasicDataMenu)
  // yield put({
  //   type: 'menu/SET_MENUBASICDATA',
  //   payload: menu4,
  // })
  // const menu3 = yield call(generalReportMenu)
  // yield put({
  //   type: 'menu/SET_REPORT_MENU',
  //   payload: menu3,
  // })
}

export function* GET_SETTING_MENU() {
  console.log('GET_SETTING_MENU')
  const payload = yield call(getSettingMenu)
  yield put({
    type: 'menu/SET_SETTING_MENU',
    payload,
  })
}

export default function* rootSaga() {
  yield all([
    GET_DATA(),
    GET_CONTRACT_MENU(),
    GET_REPORT_MENU(),
    GET_BASICDATA_MENU(),
    GET_SETTING_MENU(),
    takeEvery(actions.GET_MENU_FOR_CONTRACT, getMenuForContract),
  ])
}
