import { getRequest, postRequest, getcontractIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID')) {
    let contId = getcontractIdNumber();
    if (contId === "") contId = 0;
    searchTerm = `${searchTerm}, ContractID:${contId},`;
  }
  return getRequest(`contractInvoicePartner/get-list/${size || 10}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`)
}

export async function find(id) {
  return getRequest(`contractInvoicePartner/edit/${id}`)
}

export async function save(values) {
  return postRequest(`contractInvoicePartner/save`, values)
}

export function remove(values) {
  return postRequest(`contractInvoicePartner/delete`, values)
}
