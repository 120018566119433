import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import { SAVE_200_MSG, SAVE_200_DESC, DEL_200_MSG, DEL_200_DESC } from '../../constants'
import { list, find, save, remove } from '../../services/contractYear'

function* fetchContractYearsRefresh() {
  console.log('fetchContractYearsRefresh')
  let fe = JSON.parse(localStorage.getItem('fetchContractYears'))
  if (fe == null) {
    fe = {}
    fe.size = 10
    fe.index = 0
    fe.searchTerm = '%20'
    fe.orderBy = '%20'
  }
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, fe.size, fe.index, fe.searchTerm, fe.orderBy)
  console.log('fetchContractYearsRefresh', result)
  yield put({
    type: actions.LIST_CONTRACTYEAR,
    result,
  })
}

function* fetchContractYears({ size, index, searchTerm, orderBy }) {
  console.log('fetchContractYears', size, index, searchTerm, orderBy)

  // Load last params froom last session.
  if (!size && !index && !searchTerm && !orderBy) {
    const fe = JSON.parse(localStorage.getItem('fetchContractYears'))
    if (fe != null) {
      size = fe.size || 10
      index = fe.index || 0
      searchTerm = fe.searchTerm || '%20'
      orderBy = fe.orderBy || '%20'
    } else {
      size = 10
      index = 0
      searchTerm = '%20'
      orderBy = '%20'
    }
  }
  localStorage.setItem('fetchContractYears', JSON.stringify({ size, index, searchTerm, orderBy }))
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(list, size, index, searchTerm, orderBy)
  console.log('fetchContractYears', result)
  yield put({
    type: actions.LIST_CONTRACTYEAR,
    result,
  })
}

function* fetchContractYearEdit({ id, openModal }) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(find, id, openModal)
  yield put({
    type: actions.EDIT_CONTRACTYEAR,
    result,
    openModal,
  })
}

function* fetchContractYearSave(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(save, values)
  if (result !== null) {
    notification.success({ message: SAVE_200_MSG, description: SAVE_200_DESC })
    console.log('fetchContractYearSave result', result)
  }
  yield put({
    type: actions.SAVE_CONTRACTYEAR_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_CONTRACTYEARS,
  })
}

function* fetchContractYearDelete(values) {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(remove, values)
  if (result !== null) {
    notification.success({ message: DEL_200_MSG, description: DEL_200_DESC })
  }
  yield put({
    type: actions.DELETE_CONTRACTYEAR_RECIEVED,
    result,
  })
  yield put({
    type: actions.RE_FETCH_CONTRACTYEARS,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_CONTRACTYEARS, fetchContractYears),
    takeEvery(actions.FETCH_CONTRACTYEAR, fetchContractYearEdit),
    takeEvery(actions.SAVE_CONTRACTYEAR, fetchContractYearSave),
    takeEvery(actions.DELETE_CONTRACTYEAR, fetchContractYearDelete),
    takeEvery(actions.RE_FETCH_CONTRACTYEARS, fetchContractYearsRefresh),
  ])
}
