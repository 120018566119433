import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import countries from './country/sagas'
import postalcodes from './postalcode/sagas'
import contracts from './contracts/sagas'
import dropdowns from './dropdowns/sagas'
import costTypeDefinitions from './costTypeDefinitions/sagas'
import costTypes from './costTypes/sagas'
import costConcepts from './costConcepts/sagas'
import intervals from './intervals/sagas'
import suppliers from './suppliers/sagas'
import countrys from './countrys/sagas'
import postalCode2s from './postalCode2s/sagas'
import userss from './userss/sagas'
import technicians from './technicians/sagas'
import workOrderTypes from './workOrderTypes/sagas'
import inventoryClasss from './inventoryClasss/sagas'
import inventoryClassCostTypes from './inventoryClassCostTypes/sagas'
import locations from './locations/sagas'
import areas from './areas/sagas'
import addresss from './addresss/sagas'
import inventorys from './inventorys/sagas'
import workOrders from './workOrders/sagas'
import workOrderStatusForms from './workOrderStatusForms/sagas'
import inventoryServices from './inventoryServices/sagas'
import workOrderOperationCosts from './workOrderOperationCosts/sagas'
import reports from './reports/sagas'
import dashboard from './dashboard/sagas'
import workOrderTechnicianMaps from './workOrderTechnicianMaps/sagas'
import workOrderInventoryServiceMaps from './workOrderInventoryServiceMaps/sagas'
import parts from './parts/sagas'
import workorderpartmaps from './workorderpartmaps/sagas'
import invoices from './invoices/sagas'
import inventoryLogs from './inventoryLogs/sagas'
import inventoryProductInformations from './inventoryProductInformations/sagas'
import repairLimits from './repairLimits/sagas'
import inventoryServiceLabors from './inventoryServiceLabors/sagas'
import serviceRequests from './serviceRequests/sagas'
import technicianParts from './technicianParts/sagas'
import workOrderPhotos from './workOrderPhotos/sagas'
import technicianDailyRecords from './technicianDailyRecords/sagas'
import technicianDailyReports from './technicianDailyReports/sagas'
import partOrders from './partOrders/sagas'
import partOrderArriveds from './partOrderArriveds/sagas'
import partTransfers from './partTransfers/sagas'
import workOrderCostTypeTotals from './workOrderCostTypeTotals/sagas'
import partTransferDelivereds from './partTransferDelivereds/sagas'
import mlfbDocuments from './mlfbDocuments/sagas'
import mlfbDocumentsFolder from './mlfbDocumentsFolder/sagas'
import partTransferRecieveds from './partTransferRecieveds/sagas'
import proposals from './proposals/sagas'
import proposalDetailMaterial from './proposalDetailMaterial/sagas'
import proposalDetailWorkingHours from './proposalDetailWorkingHours/sagas'
import proposalDetailPrePriceItem from './proposalDetailPrePriceItem/sagas'
import proposalFiles from './proposalFiles/sagas'
import clinPaymentTypes from './clinPaymentTypes/sagas'
import materialPriceDefinitions from './materialPriceDefinitions/sagas'
import contactPersons from './contactPersons/sagas'
import invoiceDetails from './invoiceDetails/sagas'
import workOrderTravelCosts from './workOrderTravelCosts/sagas'
import workOrderAdditionalCosts from './workOrderAdditionalCosts/sagas'
import clinBudgets from './clinBudgets/sagas'
import invoice2s from './invoice2s/sagas'
import invoice2Lines from './invoice2Lines/sagas'
import bankInfos from './bankInfos/sagas'
import emailDeliverys from './emailDeliverys/sagas'
import companys from './companys/sagas'
import companyAddresss from './companyAddresss/sagas'
import workOrderStatusTables from './workOrderStatusTables/sagas'
import contractInvoicePartners from './contractInvoicePartners/sagas'
import contractPartners from './contractPartners/sagas'
import contractServicePartners from './contractServicePartners/sagas'

import contractServicePartnerPersons from './contractServicePartnerPersons/sagas'

import contractPartnerPersons from './contractPartnerPersons/sagas'

import contractInvoicePartnerPersons from './contractInvoicePartnerPersons/sagas'

import contractYears from './contractYears/sagas'

import mainInventory from './mainInventory/sagas'

import proposalTravelCost from './proposalTravelCosts/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    countries(),
    postalcodes(),
    contracts(),
    dropdowns(),
    costTypeDefinitions(),
    costTypes(),
    costConcepts(),
    intervals(),
    suppliers(),
    countrys(),
    postalCode2s(),
    userss(),
    technicians(),
    workOrderTypes(),
    inventoryClasss(),
    inventoryClassCostTypes(),
    locations(),
    areas(),
    addresss(),
    inventorys(),
    workOrders(),
    workOrderStatusForms(),
    inventoryServices(),
    workOrderOperationCosts(),
    reports(),
    dashboard(),
    workOrderTechnicianMaps(),
    workOrderInventoryServiceMaps(),
    parts(),
    workorderpartmaps(),
    invoices(),
    inventoryLogs(),
    inventoryProductInformations(),
    repairLimits(),
    inventoryServiceLabors(),
    serviceRequests(),
    technicianParts(),
    workOrderPhotos(),
    technicianDailyRecords(),
    technicianDailyReports(),
    partOrders(),
    partOrderArriveds(),
    partTransfers(),
    workOrderCostTypeTotals(),
    partTransferDelivereds(),
    mlfbDocuments(),
    partTransferRecieveds(),
    mlfbDocumentsFolder(),
    proposals(),
    proposalDetailMaterial(),
    proposalDetailWorkingHours(),
    proposalDetailPrePriceItem(),
    proposalFiles(),
    clinPaymentTypes(),
    materialPriceDefinitions(),
    contactPersons(),
    invoiceDetails(),
    workOrderTravelCosts(),
    workOrderAdditionalCosts(),
    clinBudgets(),
    invoice2s(),
    invoice2Lines(),
    bankInfos(),
    emailDeliverys(),
    companys(),
    companyAddresss(),

    workOrderStatusTables(),

    contractInvoicePartners(),

    contractPartners(),

    contractServicePartners(),

    contractServicePartnerPersons(),

    contractPartnerPersons(),

    contractInvoicePartnerPersons(),

    contractYears(),
    mainInventory(),

    proposalTravelCost(),
  ])
}
