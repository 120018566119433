const actions = {
  REQUESTING: 'ContractInvoicePartnerPersons/REQUESTING',
  RESPONSE_ERROR: 'ContractInvoicePartnerPersons/RESPONSE_ERROR',

  FETCH_CONTRACTINVOICEPARTNERPERSONS: 'ContractInvoicePartnerPersons/FETCH',
  LIST_CONTRACTINVOICEPARTNERPERSON: 'ContractInvoicePartnerPerson/LIST',

  FETCH_CONTRACTINVOICEPARTNERPERSON: 'ContractInvoicePartnerPerson/FETCH',
  NEW_CONTRACTINVOICEPARTNERPERSON: 'ContractInvoicePartnerPerson/NEW',
  EDIT_CONTRACTINVOICEPARTNERPERSON: 'ContractInvoicePartnerPerson/EDIT',
  RESET_CONTRACTINVOICEPARTNERPERSON: 'ContractInvoicePartnerPerson/RESET',

  RE_FETCH_CONTRACTINVOICEPARTNERPERSONS: 'ContractInvoicePartnerPersons/REFRESH',
  SAVE_CONTRACTINVOICEPARTNERPERSON: 'ContractInvoicePartnerPerson/SAVE',
  SAVE_CONTRACTINVOICEPARTNERPERSON_RECIEVED: 'ContractInvoicePartnerPerson/SAVE_CONTRACTINVOICEPARTNERPERSON_RECIEVED',

  DELETE_CONTRACTINVOICEPARTNERPERSON: 'ContractInvoicePartnerPerson/DELETE',
  DELETE_CONTRACTINVOICEPARTNERPERSON_RECIEVED: 'ContractInvoicePartnerPerson/DELETE_RECIEVED',
}

export default actions
