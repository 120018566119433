import { getRequest, postRequest } from './api'
import { API_URL_INVENTORY } from '../constants/index'

export async function list(size, index, searchTerm, orderBy) {
  return getRequest(
    `${API_URL_INVENTORY}costConcept/get-list2/${size || 15}/${index || 0}/${searchTerm ||
      '%20'}/${orderBy || '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`${API_URL_INVENTORY}costConcept/edit/${id}`)
}

export async function save(values) {
  return postRequest(`${API_URL_INVENTORY}costConcept/save`, values)
}

export function remove(values) {
  return postRequest(`${API_URL_INVENTORY}costConcept/delete`, values)
}
