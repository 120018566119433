import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions from './actions'
import {
  getNextControlDatesMonthly,
  getWorkOrderCountGroupByType,
  getWorkOrderOverLimit,
  getInventoryCount,
  getContractDetails,
  getWorkOrderTotalAmountGroupByType,
} from '../../services/dashboard'

function* fetchNextControlDatesMonthly() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getNextControlDatesMonthly)
  yield put({
    type: actions.GetNextControlDatesMonthly_RECIEVED,
    result,
  })
}

function* fetchWorkOrderCountGroupByType() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getWorkOrderCountGroupByType)
  yield put({
    type: actions.GetWorkOrderCountGroupByType_RECIEVED,
    result,
  })
}

function* fetchInventoryCount() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getInventoryCount)
  yield put({
    type: actions.GetInventoryCount_RECIEVED,
    result,
  })
}

function* fetchContractDetails() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getContractDetails)
  yield put({
    type: actions.GetContractDetails_RECIEVED,
    result,
  })
}

function* fetchWorkOrderTotalAmountGroupByType() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getWorkOrderTotalAmountGroupByType)
  yield put({
    type: actions.GetWorkOrderTotalAmountGroupByType_RECIEVED,
    result,
  })
}

function* fetchWorkOrderOverLimit() {
  yield put({
    type: actions.REQUESTING,
  })
  const result = yield call(getWorkOrderOverLimit)
  yield put({
    type: actions.GetWorkOrderOverLimitsRecieved,
    result,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GetNextControlDatesMonthly, fetchNextControlDatesMonthly),
    takeEvery(actions.GetWorkOrderCountGroupByType, fetchWorkOrderCountGroupByType),
    takeEvery(actions.GetWorkOrderTotalAmountGroupByType, fetchWorkOrderTotalAmountGroupByType),
    takeEvery(actions.GetInventoryCount, fetchInventoryCount),
    takeEvery(actions.GetContractDetails, fetchContractDetails),
    takeEvery(actions.GetWorkOrderOverLimits, fetchWorkOrderOverLimit),
  ])
}
