import actions from './actions'

export default function WorkOrdersReducer(
  state = { loading: false, editing: { workOrderID: 0 } },
  action,
) {
  switch (action.type) {
    case actions.REQUESTING:
      console.log('Workorder REQUESTING')
      return {
        ...state,
        loading: true,
        newWO: false,
        // editing: null,
      }

    case actions.ADD_INVENTORY_RECIEVED:
      console.log('Workorder ADD_INVENTORY_RECIEVED')
      console.log(action.payload)

      return {
        ...state,
        inventoryAdd: action.payload,
      }

    case actions.ADD_INVENTORY_RESET_RECIEVED:
      console.log('Workorder ADD_INVENTORY_RESET_RECIEVED')
      return {
        ...state,
        inventoryAdd: null,
      }

    case actions.LIST_WORKORDER:
      console.log('Workorder LIST_WORKORDER')
      return {
        ...state,
        editing: { workOrderID: 0 },
        result: action.result,
        loading: false,
      }

    case actions.EDIT_WORKORDER:
      console.log('Workorder EDIT_WORKORDER')

      return {
        ...state,
        editing: action.result,
        newWO: false,
        loading: false,
      }

    case actions.FETCH_NEXTCONTROLS_RECIEVED:
      console.log('Workorder FETCH_NEXTCONTROLS_RECIEVED')
      return {
        ...state,
        nextControls: action.result,
        loading: false,
      }

    case actions.RESET_WORKORDER:
      console.log('Workorder RESET_WORKORDER')
      return {
        ...state,
        editing: { workOrderID: 0 },
        loading: false,
        costs: null,
        newWO: false,
        assetHistory: null,
      }

    case actions.SAVE_WORKORDER_RECIEVED:
      console.log('Workorder SAVE_WORKORDER_RECIEVED')
      return {
        ...state,
        loading: false,
        editing: action.result,
      }

    case actions.CREATE_WORKORDER_BY_PROPOSAL_RECIEVED:
      console.log('Workorder CREATE_WORKORDER_BY_PROPOSAL_RECIEVED', action.result)
      return {
        ...state,
        loading: false,
        workorderListViewID: action.result.value,
      }

    case actions.FETCH_WORKORDER_COSTVIEW_RECIEVED:
      console.log('Workorder FETCH_WORKORDER_COSTVIEW_RECIEVED')
      return {
        ...state,
        loading: false,
        costViews: action.result,
      }

    case actions.NEW_WORKORDER:
      console.log('Workorder NEW_WORKORDER')
      return {
        ...state,
        editing: { workOrderID: 0 },
        loading: false,
      }

    case actions.CREATE_WORKORDER_RECIEVED ||
      actions.UPDATE_WORKORDER_RECIEVED ||
      actions.DELETE_WORKORDER_RECIEVED:
      console.log('Workorder CREATE_WORKORDER_RECIEVED')
      return {
        ...state,
        editing: { workOrderID: 0 },
        loading: false,
      }

    case actions.RESPONSE_ERROR:
      console.log('Workorder RESPONSE_ERROR')
      return {
        ...state,
        loading: false,
        error: true,
        saveResulted: true,
        message: action.message,
      }

    case actions.CREATE_AUTO_WORKORDERS:
      console.log('Workorder CREATE_AUTO_WORKORDERS')
      return {
        ...state,
        loading: false,
      }

    case actions.SKIP_AUTO_WORKORDERS:
      console.log('Workorder SKIP_AUTO_WORKORDERS')
      return {
        ...state,
        loading: false,
      }

    case actions.CREATE_NEW_WORKORDER:
      console.log('Workorder CREATE_NEW_WORKORDER')
      return {
        ...state,
        newWO: false,
        editing: { workOrderID: 0 },
        loading: true,
      }

    case actions.GET_APPOINTMENT_DATE_RECIEVED:
      console.log('Workorder GET_APPOINTMENT_DATE_RECIEVED')
      return {
        ...state,
        loading: false,
        appointmentDate: action.payload,
      }

    case actions.GET_APPOINTMENT_DATE_RESET_RECIEVED:
      console.log('Workorder GET_APPOINTMENT_DATE_RESET_RECIEVED')
      return {
        ...state,
        loading: false,
        appointmentDate: null,
      }

    case actions.CREATE_NEW_WORKORDER_RECIEVED:
      console.log('Workorder CREATE_NEW_WORKORDER_RECIEVED')
      return {
        ...state,
        newWO: true,
        editing: action.result,
        loading: false,
      }

    case actions.CREATE_NEW_WORKORDER_RESET_RECIEVED:
      console.log('Workorder CREATE_NEW_WORKORDER_RESET_RECIEVED')
      return {
        ...state,
        newWO: false,
        recall: null,
      }

    case actions.FETCH_WORKORDER_ASSET_REPORT_RECIEVED:
      console.log('Workorder FETCH_WORKORDER_ASSET_REPORT_RECIEVED')
      return {
        ...state,
        loading: false,
        assetHistory: {
          result: { items: action.result, count: action.result ? action.result.length : 0 },
        },
      }

    case actions.FETCH_WORKORDER_ASSET_REPORT_RESET_RECIEVED:
      console.log('Workorder FETCH_WORKORDER_ASSET_REPORT_RESET_RECIEVED')
      return {
        ...state,
        loading: false,
        assetHistory: null,
        // assetHistoryCount: null
      }

    case actions.FETCH_WORKORDER_ASSET_COUNT_RECIEVED:
      console.log('Workorder FETCH_WORKORDER_ASSET_COUNT_RECIEVED')
      return {
        ...state,
        loading: false,
        assetHistoryCount: action.result,
      }

    case actions.RECALL_WORKORDER_RECIEVED:
      console.log('Workorder RECALL_WORKORDER_RECIEVED')
      return {
        ...state,
        loading: false,
        recall: action.result,
      }

    case actions.PRINT_POPUP_RECIEVED:
      console.log('Workorder PRINT_POPUP_RECIEVED')
      return {
        ...state,
        loading: false,
        printPopup: true,
      }

    case actions.PRINT_POPUP_RESET_RECIEVED:
      console.log('Workorder PRINT_POPUP_RESET_RECIEVED')
      return {
        ...state,
        loading: false,
        printPopup: false,
      }

    case actions.POPUP_BUDGET_RECIEVED:
      console.log('Workorder POPUP_BUDGET_RECIEVED')
      return {
        ...state,
        loading: false,
        budgetPopup: true,
      }

    case actions.POPUP_BUDGET_RESET_RECIEVED:
      console.log('Workorder POPUP_BUDGET_RESET_RECIEVED')
      return {
        ...state,
        loading: false,
        budgetPopup: false,
      }

    case actions.OPEN_POPUP_CUSTOMER_SIGNATURE_RECIEVED:
      console.log('Workorder OPEN_POPUP_CUSTOMER_SIGNATURE_RECIEVED')
      return {
        ...state,
        loading: false,
        customerSignaturePopup: true,
      }

    case actions.CLOSE_POPUP_CUSTOMER_SIGNATURE_RECIEVED:
      console.log('Workorder CLOSE_POPUP_CUSTOMER_SIGNATURE_RECIEVED')
      return {
        ...state,
        loading: false,
        customerSignaturePopup: false,
      }

    case actions.OPEN_POPUP_TECHNICIAN_WORK_RECIEVED:
      console.log('Workorder OPEN_POPUP_TECHNICIAN_WORK_RECIEVED')
      return {
        ...state,
        loading: false,
        technicianWorkPopup: true,
      }

    case actions.CLOSE_POPUP_TECHNICIAN_WORK_RECIEVED:
      console.log('Workorder CLOSE_POPUP_TECHNICIAN_WORK_RECIEVED')
      return {
        ...state,
        loading: false,
        technicianWorkPopup: false,
      }

    case actions.OPEN_POPUP_SERVICE_ORDER_FILE_RECIEVED:
      console.log('Workorder OPEN_POPUP_SERVICE_ORDER_FILE_RECIEVED')
      return {
        ...state,
        loading: false,
        serviceOrderFilePopup: true,
      }

    case actions.CLOSE_POPUP_SERVICE_ORDER_FILE_RECIEVED:
      console.log('Workorder CLOSE_POPUP_SERVICE_ORDER_FILE_RECIEVED')
      return {
        ...state,
        loading: false,
        serviceOrderFilePopup: false,
      }

    case actions.OPEN_POPUP_WORKORDER_COST_SUMMARY_RECIEVED:
      console.log('Workorder OPEN_POPUP_WORKORDER_COST_SUMMARY_RECIEVED')
      return {
        ...state,
        loading: false,
        costSummaryPopup: true,
      }

    case actions.CLOSE_POPUP_WORKORDER_COST_SUMMARY_RECIEVED:
      console.log('Workorder CLOSE_POPUP_WORKORDER_COST_SUMMARY_RECIEVED')
      return {
        ...state,
        loading: false,
        costSummaryPopup: false,
      }

    case actions.OPEN_POPUP_WORKORDER_PROPOSAL_INFO_RECIEVED:
      console.log('Workorder OPEN_POPUP_WORKORDER_PROPOSAL_INFO_RECIEVED')
      return {
        ...state,
        loading: false,
        proposalInfoPopup: true,
      }

    case actions.CLOSE_POPUP_WORKORDER_PROPOSAL_INFO_RECIEVED:
      console.log('Workorder CLOSE_POPUP_WORKORDER_PROPOSAL_INFO_RECIEVED')
      return {
        ...state,
        loading: false,
        proposalInfoPopup: false,
      }

    case actions.OPEN_POPUP_WORKORDER_EMAIL_SEND_RECIEVED:
      console.log('Workorder OPEN_POPUP_WORKORDER_EMAIL_SEND_RECIEVED')
      return {
        ...state,
        loading: false,
        emailSendPopup: true,
      }

    case actions.CLOSE_POPUP_WORKORDER_EMAIL_SEND_RECIEVED:
      console.log('Workorder CLOSE_POPUP_WORKORDER_EMAIL_SEND_RECIEVED')
      return {
        ...state,
        loading: false,
        emailSendPopup: false,
      }

    case actions.OPEN_POPUP_WORKORDER_ATTACHMENT_RECIEVED:
      console.log('Workorder OPEN_POPUP_WORKORDER_ATTACHMENT_RECIEVED')
      return {
        ...state,
        loading: false,
        attachmentPopup: true,
      }

    case actions.CLOSE_POPUP_WORKORDER_ATTACHMENT_RECIEVED:
      console.log('Workorder CLOSE_POPUP_WORKORDER_ATTACHMENT_RECIEVED')
      return {
        ...state,
        loading: false,
        attachmentPopup: false,
      }

    case actions.OPEN_POPUP_WORKORDERLISTBYMAININVENTORY_RECIEVED:
      console.log('Workorder OPEN_POPUP_WORKORDERLISTBYMAININVENTORY_RECIEVED')
      return {
        ...state,
        loading: false,
        workorderListByMainInventoryPopup: true,
      }

    case actions.CLOSE_POPUP_WORKORDERLISTBYMAININVENTORY_RECIEVED:
      console.log('Workorder CLOSE_POPUP_WORKORDERLISTBYMAININVENTORY_RECIEVED')
      return {
        ...state,
        loading: false,
        workorderListByMainInventoryPopup: false,
      }

    case actions.GET_COSTVIEW_RECIVEVED2:
      console.log('Workorder GET_COSTVIEW_RECIVEVED2', action.payload)
      return {
        ...state,
        loading: false,
        costViews2: action.payload,
      }

    case actions.GET_WORKORDERLISTBYMAININVENTORY_RECIEVED:
      console.log('Workorder GET_WORKORDERLISTBYMAININVENTORY_RECIEVED', action.payload)
      return {
        ...state,
        loading: false,
        workorderListByMainInventory: action.payload,
      }

    default:
      return state
  }
}
