const actions = {
  REQUESTING: 'ContractInvoicePartners/REQUESTING',
  RESPONSE_ERROR: 'ContractInvoicePartners/RESPONSE_ERROR',

  FETCH_CONTRACTINVOICEPARTNERS: 'ContractInvoicePartners/FETCH',
  LIST_CONTRACTINVOICEPARTNER: 'ContractInvoicePartner/LIST',

  FETCH_CONTRACTINVOICEPARTNER: 'ContractInvoicePartner/FETCH',
  NEW_CONTRACTINVOICEPARTNER: 'ContractInvoicePartner/NEW',
  EDIT_CONTRACTINVOICEPARTNER: 'ContractInvoicePartner/EDIT',
  RESET_CONTRACTINVOICEPARTNER: 'ContractInvoicePartner/RESET',

  RE_FETCH_CONTRACTINVOICEPARTNERS: 'ContractInvoicePartners/REFRESH',
  SAVE_CONTRACTINVOICEPARTNER: 'ContractInvoicePartner/SAVE',
  SAVE_CONTRACTINVOICEPARTNER_RECIEVED: 'ContractInvoicePartner/SAVE_CONTRACTINVOICEPARTNER_RECIEVED',

  DELETE_CONTRACTINVOICEPARTNER: 'ContractInvoicePartner/DELETE',
  DELETE_CONTRACTINVOICEPARTNER_RECIEVED: 'ContractInvoicePartner/DELETE_RECIEVED',
}

export default actions
