import { getRequest, postRequest, getcontractIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  if (!searchTerm.includes('ContractID'))
    searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
  return getRequest(
    `inventoryClass/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy ||
      '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`inventoryClass/edit/${id}`)
}

export async function save(values) {
  return postRequest(`inventoryClass/save`, values)
}

export function remove(values) {
  return postRequest(`inventoryClass/delete`, values)
}

export async function importData(values) {
  console.log('importData', values)
  const headers = {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
  }
  return postRequest(`inventoryClass/import`, values, headers)
}
