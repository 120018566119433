import { getRequest, postRequest, getcompanyIdNumber } from './api'

export async function list(size, index, searchTerm, orderBy) {
  searchTerm = 'webrequest'
  if (!searchTerm.includes('CompanyID')) {
    searchTerm = `${searchTerm}, CompanyID:${getcompanyIdNumber()},`
  }

  if (!searchTerm.includes('IsArchived')) {
    searchTerm = `${searchTerm}, IsArchived:false,`
  }

  return getRequest(
    `contract/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy || '%20'}`,
  )
}

export async function find(id) {
  console.log('find id', id)
  return getRequest(`contract/edit/${id}`)
}

export async function save(values) {
  return postRequest(`contract/save`, values)
}

export function remove(values) {
  return postRequest(`contract/delete`, values)
}
