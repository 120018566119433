const actions = {
  REQUESTING: 'ProposalTravelCost/REQUESTING',
  RESPONSE_ERROR: 'ProposalTravelCost/RESPONSE_ERROR',

  FETCH_PROPOSALTRAVELCOSTS: 'ProposalTravelCost/FETCH_LIST',
  LIST_PROPOSALTRAVELCOST: 'ProposalTravelCost/LIST',

  FETCH_PROPOSALTRAVELCOST: 'ProposalTravelCost/FETCH',
  NEW_PROPOSALTRAVELCOST: 'ProposalTravelCost/NEW',
  EDIT_PROPOSALTRAVELCOST: 'ProposalTravelCost/EDIT',
  RESET_PROPOSALTRAVELCOST: 'ProposalTravelCost/RESET',

  RE_FETCH_PROPOSALTRAVELCOSTS: 'ProposalTravelCost/REFRESH',
  SAVE_PROPOSALTRAVELCOST: 'ProposalTravelCost/SAVE',
  SAVE_PROPOSALTRAVELCOST_RECIEVED: 'ProposalTravelCost/SAVE_PROPOSALTRAVELCOST_RECIEVED',

  DELETE_PROPOSALTRAVELCOST: 'ProposalTravelCost/DELETE',
  DELETE_PROPOSALTRAVELCOST_RECIEVED: 'ProposalTravelCost/DELETE_RECIEVED',
}

export default actions
