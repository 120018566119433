const actions = {
  REQUESTING: 'ContractServicePartnerPersons/REQUESTING',
  RESPONSE_ERROR: 'ContractServicePartnerPersons/RESPONSE_ERROR',

  FETCH_CONTRACTSERVICEPARTNERPERSONS: 'ContractServicePartnerPersons/FETCH',
  LIST_CONTRACTSERVICEPARTNERPERSON: 'ContractServicePartnerPerson/LIST',

  FETCH_CONTRACTSERVICEPARTNERPERSON: 'ContractServicePartnerPerson/FETCH',
  NEW_CONTRACTSERVICEPARTNERPERSON: 'ContractServicePartnerPerson/NEW',
  EDIT_CONTRACTSERVICEPARTNERPERSON: 'ContractServicePartnerPerson/EDIT',
  RESET_CONTRACTSERVICEPARTNERPERSON: 'ContractServicePartnerPerson/RESET',

  RE_FETCH_CONTRACTSERVICEPARTNERPERSONS: 'ContractServicePartnerPersons/REFRESH',
  SAVE_CONTRACTSERVICEPARTNERPERSON: 'ContractServicePartnerPerson/SAVE',
  SAVE_CONTRACTSERVICEPARTNERPERSON_RECIEVED: 'ContractServicePartnerPerson/SAVE_CONTRACTSERVICEPARTNERPERSON_RECIEVED',

  DELETE_CONTRACTSERVICEPARTNERPERSON: 'ContractServicePartnerPerson/DELETE',
  DELETE_CONTRACTSERVICEPARTNERPERSON_RECIEVED: 'ContractServicePartnerPerson/DELETE_RECIEVED',
}

export default actions
