import { getRequest, postRequest, deleteRequest, getProposalID } from './api'
import { API_URL } from '../constants/index'

export async function list() {
  const url = new URL(`${API_URL}proposalTravelCost`)

  const queryString = url.search

  const searchParams = new URLSearchParams(queryString)

  const proposalID = getProposalID()

  if (proposalID !== null && proposalID !== undefined) {
    searchParams.append('proposalID', proposalID)
  }

  url.search = searchParams.toString()

  const newUrl = url.toString()

  return getRequest(newUrl)
}

export async function find(id) {
  return getRequest(`proposalTravelCost/${id}`)
}

export async function save(values) {
  return postRequest(`proposalTravelCost`, values)
}

export function remove(id) {
  return deleteRequest(`proposalTravelCost/${id}`)
}
