import { getRequest, postRequest, getcontractIdNumber, deleteRequest } from './api'
import { API_URL } from '../constants/index'

export async function list(size, index, searchTerm, orderBy) {
  searchTerm = `${searchTerm}, ContractID:${getcontractIdNumber()},`
  return getRequest(
    `${API_URL}address/get-list/${size || 15}/${index || 0}/${searchTerm || '%20'}/${orderBy ||
      '%20'}`,
  )
}

export async function find(id) {
  return getRequest(`${API_URL}address/edit/${id}`)
}

export async function save(values) {
  return postRequest(`${API_URL}address/save`, values)
}

export function remove(id) {
  console.log('remove', id)
  return deleteRequest(`${API_URL}address/${id}`)
}

export async function importData(values) {
  console.log('importData', values)
  const headers = {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
  }
  return postRequest(`${API_URL}address/import`, values, headers)
}
